import { FormControl, MenuItem, TextField, Tooltip } from "@mui/material";
import { capitalize } from "lodash";
import { SharedCDS } from "./constants";

export const AgeGroupField = ({
  ageGroup,
  setAgeGroup,
  disabled,
  currentPatient,
  updateAgeGroup,
}) => {
  const hasDOB = !!currentPatient?.birthDate;

  return (
    <FormControl fullWidth>
      <Tooltip
        title={hasDOB ? "Age group is determined by the patient's age" : ""}
        placement="top"
      >
        <TextField
          value={ageGroup}
          disabled={disabled || hasDOB}
          onChange={(e) => {
            setAgeGroup(e.target.value);
            updateAgeGroup(e.target.value);
          }}
          id="vitals-respiratory-rate-age-group"
          label="Age Group"
          select
          variant="outlined"
          size="medium"
        >
          {Object.keys(SharedCDS.ageRanges).map((key) => (
            <MenuItem value={key} key={key}>
              {capitalize(key)}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
    </FormControl>
  );
};
