import { Coding } from "../../../../../types/codeableConcept";
import NotImplemented from "../../../../NotImplemented";
import BooleanQuestion from "./Boolean";
import DateQuestion from "./Date";
import GroupItems from "./Group";
import Question from "./Question";
import IntegerQuestion from "./Integer";
import TimeQuestion from "./Time";
import StringQuestion from "./String";
import TextQuestion from "./Text";
import UrlQuestion from "./Url";
import { every, get, pick, some } from "lodash";
import ChoiceQuestion from "./Choice";
import DateTimeQuestion from "./DateTime";
import DecimalQuestion from "./Decimal";
import OpenChoiceQuestion from "./OpenChoice";
import MultiChoiceQuestion from "./MultiChoice";
import Calculated from "./Calculated";
import GenderOption from "./GenderOption";
import { Add, Remove } from "@mui/icons-material";
import SubGroupItems from "./SubGroup";
import { set as fpSet } from "lodash/fp";

export interface QuestionnaireItem {
  linkId: string;
  definition: string;
  code: Coding[];
  prefix: string;
  text: string;
  type: string;
  enableWhen:
    | {
        question: string;
        operator: string;
        answer: any;
      }
    | { question: string; operator: string; answer: any }[];
  enableBehavior: string;
  required: boolean;
  repeats: boolean;
  readOnly: boolean;
  maxLength: number;
  answerValueSet: any;
  answerOption: any[];
  initial: any;
  item: QuestionnaireItem[];
  answer: any;
  formula?: string;
}

export interface QuestionnaireItemProps {
  item: QuestionnaireItem;
  onChange: (item: QuestionnaireItem, value: any) => void;
  value: any;
  initialValue: any;
  disabled: boolean;
  required: boolean;
  answers: any;
  answerValueSet: any;
  isMultiple: boolean;
  enableGroupNote?: boolean;
  onChangeNote?: (value: string, item: QuestionnaireItem) => void;
  notes?: Record<string, any>;
  disableCloseOnSelect?: boolean;
  formula?: string;
  groupAction?: any;
  sex?: "male" | "female";
}

//http://hl7.org/fhir/valueset-item-type.html
export const QuestionnaireItemTypes = {
  group: GroupItems,
  subgroup: SubGroupItems,
  display: NotImplemented,
  question: Question,
  boolean: BooleanQuestion,
  decimal: DecimalQuestion,
  integer: IntegerQuestion,
  date: DateQuestion,
  dateTime: DateTimeQuestion,
  time: TimeQuestion,
  string: StringQuestion,
  text: TextQuestion,
  url: UrlQuestion,
  choice: ChoiceQuestion,
  "multi-choice": MultiChoiceQuestion,
  "open-choice": OpenChoiceQuestion,
  attachment: NotImplemented,
  reference: NotImplemented,
  quantity: NotImplemented,
  calculation: Calculated,
  "gender-option": GenderOption,
};

export const variantMapping = {
  positive: "success",
  negative: "error",
  neutral: "default",
};

export const variantIconMapping = {
  positive: Add,
  negaive: Remove,
};

export const createQuestionnaireItemPath = (indices: string[]) => {
  const path = indices.map((index) => {
    const num = Number(index);
    return `item[${num - 1}]`;
  });
  return path.join(".");
};

export const createResponseItem = (item: QuestionnaireItem, value: any) => {
  const responseItem: any = pick(item, ["linkId", "definition", "text"]);
  responseItem.answer = createAnswersFromValue(value, item.answer);
  return responseItem;
};

function createAnswersFromValue(value: any, answer: any): any {
  if (value) {
    if (Array.isArray(value)) {
      return value.map((val) => {
        if (val.value) {
          return val;
        }
        return { value: val, ...answer };
      });
    } else {
      return { value, ...answer };
    }
  }
  return undefined;
}

export const allBoxesChecked = (item: any, answers: any) => {
  const matchAnswerIds = Object.keys(answers).filter((answer) => {
    const split = answer.split(".");
    if (split[0] === item.linkId && answers[answer]) return true;
    return false;
  });
  return matchAnswerIds.length === item.item.length;
};

export const getSelections = (items) => {
  const selections = [];
  for (let i = 0; i < items?.length; i++) {
    const item = items[i];
    // if (item.text && !item.answer) {
    //   selection.push(`${item?.text}`);
    // }
    if (item?.answer && item.answer?.value === true) {
      selections.push({
        text: `✓ ${item?.text}`,
        note: item?.answer?.note,
      });
    }
  }
  return selections;
};

export const enableQuestion = (item: any, answers: any, onChange): boolean => {
  const { enableWhen, enableBehavior } = item;

  if (enableWhen && enableWhen.length > 0) {
    let isEnabled = false;
    if (enableBehavior === "all") {
      isEnabled = every(
        enableWhen.map((criteria) => evaluateEnableCriteria(criteria, answers)),
      );
    } else {
      isEnabled = some(
        enableWhen.map((criteria) => evaluateEnableCriteria(criteria, answers)),
      );
    }
    if (!isEnabled) {
      //TODO: null out answers to for questions not enabled
      const val =
        get(answers[item?.linkId], `value.code`) ||
        get(answers[item?.linkId], `value`);

      if (val) {
        onChange(item, undefined);
      }
    }
    return isEnabled;
  }
  return true;
};

const operators = {
  "=": "==",
  "!=": "!=",
  ">": ">",
  "<": "<",
  ">=": ">=",
  "<=": "<=",

  // exists | = | != | > | < | >= | <=
};

const evaluateEnableCriteria = (criteria, answers) => {
  const value =
    get(answers[criteria.question], `value.code`) ||
    get(answers[criteria.question], `value`);

  try {
    // eslint-disable-next-line no-eval
    const enabled = eval(
      `"${value}" ${operators[criteria.operator]} "${criteria.answer}"`,
    );
    return enabled;
  } catch (e) {
    console.error("an error occured when evaluating questionnaire", e);
    return false;
  }
};

export const createGroupFlattenedResponses = (items) => {
  let flattened = {};
  if (items && items.length > 0) {
    items.forEach((item) => {
      if (item && item.linkId && item.answer) {
        flattened[item.linkId] = item.answer;
      }

      if (item && item?.length > 0) {
        flattened = { ...flattened, ...createGroupFlattenedResponses(item) };
      }
    });
  }

  return flattened;
};

export const createFlattendResponses = (items) => {
  let flattened = {};
  if (items && items.length > 0) {
    items.forEach((item) => {
      if (item) {
        if (item?.linkId) {
          flattened[item?.linkId] = item?.answer;
        }
        if (item && item?.item && item?.item?.length > 0) {
          flattened = { ...flattened, ...createFlattendResponses(item?.item) };
        }
      }
    });

    return flattened;
  }
  return {};
};

export const unpackGroupResponse = (data) => {
  const responseItems = structuredClone(data?.item ?? []);

  const unpackedData = [];
  const unpackedNotes = {};
  for (let i = 0; i < responseItems.length; i++) {
    const element = responseItems[i];
    if (!element) continue;

    unpackedData.push(element.item ?? []);
    unpackedNotes[element.linkId] = element ? element.note : [];
  }

  return [createGroupFlattenedResponses(unpackedData), unpackedNotes] as const;
};
