import { FormControl, FormLabel, Grid } from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";
import DictationControl from "../../../../../components/dictation/DictationControl";

const TextQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  initialValue,
  disabled,
}) => {
  return (
    <Grid>
      <FormControl fullWidth>
        <FormLabel>{item.text}</FormLabel>
        <DictationControl
          multiline-={true}
          maxRows={5}
          minRows={3}
          variant="outlined"
          defaultValue={initialValue}
          value={value?.value || initialValue}
          onTextChange={(e) => onChange(item, e)}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
  );
};

export default TextQuestion;
