import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

const TimeQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  disabled,
  initialValue,
  required,
}) => {
  return (
    <Grid>
      <FormControl>
        <FormLabel required={required}>{item.text}</FormLabel>
        <TimePicker
          value={value?.value || initialValue}
          onChange={(e) => onChange(item, e.target.value)}
          renderInput={(params) => <TextField {...params} />}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
  );
};

export default TimeQuestion;
