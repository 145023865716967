/* eslint-disable react-refresh/only-export-components */
import { RouteObject } from "react-router";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout2 from "./components/dashboard/DashboardLayout2";
import GuestGuard from "./components/GuestGuard";
import { Loadable } from "./components/Loadable";
import MainLayout from "./components/MainLayout";
import lazyWithRetry from "./LazyLoadWithRetry";

const BroadcastDashboardPage = Loadable(
  lazyWithRetry(
    () => import("./pages/communications/broadcast/BroadcastDashboard"),
  ),
);
const BroadcastFormPage = Loadable(
  lazyWithRetry(() => import("./pages/communications/broadcast/BroadcastForm")),
);

// Authentication pages
const Login = Loadable(
  lazyWithRetry(() => import("./pages/authentication/Login")),
);
const PasswordRecovery = Loadable(
  lazyWithRetry(() => import("./pages/authentication/PasswordRecovery")),
);
const PasswordReset = Loadable(
  lazyWithRetry(() => import("./pages/authentication/PasswordReset")),
);
const Register = Loadable(
  lazyWithRetry(() => import("./pages/authentication/Register")),
);
const VerifyCode = Loadable(
  lazyWithRetry(() => import("./pages/authentication/VerifyCode")),
);

const Overview = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/Overview")),
);

const HealthInformataionSummary = Loadable(
  lazyWithRetry(() => import("./pages/health/HealthInformationSummary")),
);
const MedicalEncounterHome = Loadable(
  lazyWithRetry(() => import("./pages/health/MedicalEncounterHome")),
);

const MedicalEncounterPrint = Loadable(
  lazyWithRetry(() => import("./pages/health/encounters/Print/EncounterPrint")),
);

const CarePlanHome = Loadable(
  lazyWithRetry(() => import("./pages/health/CarePlanHome")),
);

const MedicationAdministration = Loadable(
  lazyWithRetry(
    () =>
      import(
        "./pages/health/medication-administration/MedicationAdministrationHome"
      ),
  ),
);

const MedicationAdministrationSummary = Loadable(
  lazyWithRetry(
    () =>
      import(
        "./pages/health/medication-administration/MedicationAdministrationSummary"
      ),
  ),
);

const MedicalEncounterSummary = Loadable(
  lazyWithRetry(
    () => import("./pages/health/encounters/MedicalEncounterSummary"),
  ),
);
const WrappedEncounterCarePlan = Loadable(
  lazyWithRetry(
    () => import("./pages/health/encounters/WrappedEncounterCarePlan"),
  ),
);
const PrescriptionList = Loadable(
  lazyWithRetry(() => import("./pages/health/prescriptions/PrescriptionList")),
);

const AppointmentDashboard = Loadable(
  lazyWithRetry(() => import("./pages/appointment/AppointmentDashboard")),
);
const AppointmentFormPage = Loadable(
  lazyWithRetry(() => import("./pages/appointment/AppointmentForm")),
);
const AppointmentListPage = Loadable(
  lazyWithRetry(() => import("./pages/appointment/AppointmentsTable")),
);

const PatientDashboard = Loadable(
  lazyWithRetry(() => import("./pages/patient/PatientDashboard")),
);
const PatientForm = Loadable(
  lazyWithRetry(() => import("./pages/patient/PatientForm")),
);
const PatientView = Loadable(
  lazyWithRetry(() => import("./pages/patient/PatientView")),
);
const ProviderDashboard = Loadable(
  lazyWithRetry(() => import("./pages/provider/ProviderDashboard")),
);

const PractitionerForm = Loadable(
  lazyWithRetry(() => import("./pages/provider/PractitionerForm")),
);
const PractitionerView = Loadable(
  lazyWithRetry(() => import("./pages/provider/PractitionerView")),
);

const FacilityHome = Loadable(lazyWithRetry(() => import("./pages/facility")));

const FacilityForm = Loadable(
  lazyWithRetry(() => import("./pages/facility/forms/FacilityForm")),
);

const FacilityServiceForm = Loadable(
  lazyWithRetry(() => import("./pages/facility/forms/FacilityServiceForm")),
);

const MedicalConfigurationForm = Loadable(
  lazyWithRetry(
    () => import("./pages/facility/forms/MedicalConfigurationForm"),
  ),
);

//dashboards
const ExecutiveDashboard = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/ExecutiveDashboard")),
);

const TriageDashboard = Loadable(
  lazyWithRetry(() => import("./pages/dashboard/TriageDashboard")),
);

// Admin pages
const AdminProviderEditPage = Loadable(
  lazyWithRetry(() => import("./pages/provider/Admin/AdminProviderEditPage")),
);

// Error pages
const AuthorizationRequired = Loadable(
  lazyWithRetry(() => import("./pages/AuthorizationRequired")),
);
const NotFound = Loadable(lazyWithRetry(() => import("./pages/NotFound")));
const ServerError = Loadable(
  lazyWithRetry(() => import("./pages/ServerError")),
);

type MedialRouteObject = RouteObject & {
  ldFlag?: string;
  ldOrgFlag?: string;
  tier?: string;
  userRole?: string;
};

// TODO: Stephen to implement route filtering using props in MedialRouteObject
const routes: MedialRouteObject[] = [
  {
    path: "auth",
    children: [
      { path: "login-unguarded", element: <Login /> },
      { path: "password-recovery", element: <PasswordRecovery /> },
      { path: "password-reset", element: <PasswordReset /> },
      { path: "verify-code", element: <VerifyCode /> },
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "home",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      {
        path: "summary",
        element: <ExecutiveDashboard />,
      },
      { path: "", element: <Overview /> },
    ],
  },
  {
    path: "health",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      { path: "summary", element: <HealthInformataionSummary /> },
      { path: "triage", element: <TriageDashboard /> },
      {
        path: "encounters",
        children: [
          { index: true, element: <MedicalEncounterHome /> },
          {
            path: ":encounterId",
            children: [
              {
                index: true,
                element: <MedicalEncounterSummary interactivity="none" />,
              },
              {
                path: "edit",
                element: <MedicalEncounterSummary interactivity="full" />,
              },
              {
                path: "plan",
                element: <WrappedEncounterCarePlan interactivity="full" />,
              },
              {
                path: "plan/view",
                element: <WrappedEncounterCarePlan interactivity="none" />,
              },
              { path: "print", element: <MedicalEncounterPrint /> },
            ],
          },
        ],
      },

      {
        path: "plans",
        children: [
          {
            path: "",
            element: <CarePlanHome />,
          },
        ],
      },

      // prescriptions
      { path: "prescriptions", element: <PrescriptionList /> },

      // medication-administration
      {
        path: "medication-administrations",
        children: [
          {
            path: "",
            element: <MedicationAdministration />,
          },
          {
            path: ":encounterId/edit",
            element: <MedicationAdministrationSummary interactivity="full" />,
          },
          {
            path: ":encounterId/view",
            element: <MedicationAdministrationSummary interactivity="none" />,
          },
        ],
      },
    ],
  },
  {
    path: "patients",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      { path: "", element: <PatientDashboard /> },
      { path: "new", element: <PatientForm /> },
      {
        path: ":patientId",
        children: [
          { path: "", element: <PatientView /> },
          { path: "edit", element: <PatientForm /> },
        ],
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [{ path: "staff/:id/edit", element: <AdminProviderEditPage /> }],
  },
  {
    path: "staff",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      { path: "", element: <ProviderDashboard /> },
      { path: ":practitionerId", element: <PractitionerView /> },
      { path: "profile", element: <PractitionerForm /> },
      { path: ":practitionerId/edit", element: <PractitionerForm /> },
    ],
  },
  {
    path: "facility",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      { path: "", element: <FacilityHome /> },
      { path: "edit", element: <FacilityForm /> },
      { path: "services/new", element: <FacilityServiceForm /> },
      { path: "services/:serviceId", element: <FacilityServiceForm /> },
      { path: "medical-configuration", element: <MedicalConfigurationForm /> },
    ],
  },
  {
    path: "communications",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      {
        path: "broadcast",
        element: <BroadcastDashboardPage />,
      },
      {
        path: "broadcast/new",
        element: <BroadcastFormPage interactivity="full" />,
      },
      {
        path: "broadcast/:broadcastId",
        element: <BroadcastFormPage interactivity="none" />,
      },
      {
        path: "broadcast/:broadcastId/edit",
        element: <BroadcastFormPage interactivity="full" />,
      },
    ],
  },
  {
    path: "appointments",
    element: (
      <AuthGuard>
        <DashboardLayout2 />
      </AuthGuard>
    ),
    children: [
      { path: "", element: <AppointmentDashboard /> },
      { path: "new", element: <AppointmentFormPage /> },
      { path: ":appointmentId", element: <AppointmentFormPage /> },
      { path: "search", element: <AppointmentListPage /> },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      { path: "401", element: <AuthorizationRequired /> },
      { path: "404", element: <NotFound /> },
      { path: "500", element: <ServerError /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export default routes;
