import { createContext, useState, useCallback } from "react";
import { Appointment } from "../types/appointment";
import { Patient } from "../types/patient";

const defaultApi = {
  isOpen: undefined,
  // encounterId: string,
  appointment: undefined,
  patient: undefined,
  openMedicalEncounterModal: (): void => null,
  closeMedicalEncounterModal: (): void => null,
  setEncounterAppointment: (appointment: Appointment): void => null,
  setEncounterPatient: (patient: Patient): void => null,
};

export type MedicalEncounterModalValue = typeof defaultApi;

export const MedicalEncounterModalContext =
  createContext<MedicalEncounterModalValue>(defaultApi);

export function MedicalEncounterModalProvider({ children }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<Appointment>();

  const [patient, setPatient] = useState<Patient>();

  const openMedicalEncounterModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeMedicalEncounterModal = useCallback(() => {
    setIsOpen(false);
    setAppointment(undefined);
  }, []);

  const setEncounterAppointment = (encounterAppointment) => {
    setAppointment(encounterAppointment);
  };

  const setEncounterPatient = (pencounterPatient) => {
    setPatient(pencounterPatient);
  };

  // Return Provider with full API
  const api = {
    isOpen,
    appointment,
    patient,
    openMedicalEncounterModal,
    closeMedicalEncounterModal,
    setEncounterAppointment,
    setEncounterPatient,
  };
  return (
    <MedicalEncounterModalContext.Provider value={api}>
      {children}
    </MedicalEncounterModalContext.Provider>
  );
}
