import "regenerator-runtime";
import "nprogress/nprogress.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import { ConfirmProvider } from "material-ui-confirm";
import React, { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { registerLicense } from "@syncfusion/ej2-base";
import { NotificationsProvider } from "./components/dashboard/NotificationsProvider";
import { AppointmentDashboardProvider } from "./contexts/AppointmentDashboardContext";
import { AuthProvider } from "./contexts/Auth0Context";
import { DictactionProvider } from "./contexts/DictationProvider";
import { DuplicatePatientModalProvider } from "./contexts/DuplicatePatientModalProvider";
import { FullPageLoaderProvider } from "./contexts/FullPageLoaderProvider";
import { MedicalEncounterModalProvider } from "./contexts/MedicalEncounterModalProvider";
import { MedicalEncounterProvider } from "./contexts/MedicalEncounterProvider";
import { OrgSelectionModalModalProvider } from "./contexts/OrgSelectionModalProvider";
import { SettingsProvider } from "./contexts/SettingsContext";
import { UserProfileModalProvider } from "./contexts/UserProfileModalProvider";
import { ScreenWidthProvider } from "./contexts/ScreenWidthContext";
import store from "./store";
import App from "./App";
import { ExecutorProvider } from "./contexts/ExecutorContext";
import AxiosInterceptor from "./components/Axios/AxiosInterceptor";

registerLicense(
  "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9SdENjUHtbcndRR2lV",
);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: import.meta.env.VITE_SENTRY_MASK_ALL_TEXT,
      blockAllMedia: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: undefined,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: !import.meta.env.DEV,
  enableTracing: !import.meta.env.DEV,
});

if (import.meta.env.DEV) {
  console.warn(
    "Sentry is disabled in development mode. Turn `NODE_ENV` to `production` to manually enable.",
  );
}

window.addEventListener("vite:preloadError", () => {
  window.location.reload(); // for example, refresh the page
});

// Create a client
const queryClient = new QueryClient();

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ScreenWidthProvider>
              <SettingsProvider>
                <AppointmentDashboardProvider>
                  <BrowserRouter>
                    <AuthProvider>
                      <AxiosInterceptor />
                      <NotificationsProvider>
                        <ConfirmProvider>
                          <UserProfileModalProvider>
                            <OrgSelectionModalModalProvider>
                              <DuplicatePatientModalProvider>
                                <MedicalEncounterModalProvider>
                                  <MedicalEncounterProvider>
                                    <DictactionProvider>
                                      <FullPageLoaderProvider>
                                        <ExecutorProvider>
                                          <App />
                                        </ExecutorProvider>
                                      </FullPageLoaderProvider>
                                    </DictactionProvider>
                                  </MedicalEncounterProvider>
                                </MedicalEncounterModalProvider>
                              </DuplicatePatientModalProvider>
                            </OrgSelectionModalModalProvider>
                          </UserProfileModalProvider>
                        </ConfirmProvider>
                      </NotificationsProvider>
                    </AuthProvider>
                  </BrowserRouter>
                </AppointmentDashboardProvider>
              </SettingsProvider>
            </ScreenWidthProvider>
          </LocalizationProvider>
        </ReduxProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root"),
);
