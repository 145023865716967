import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { QuestionnaireItemProps } from ".";

const createStep = (value) => {
  let finalStep = "";
  const val2String = String(value);

  for (let i = val2String.length - 1; i >= 0; i--) {
    if (i === val2String.length - 1) finalStep += "1";
    else if (val2String[i] === ".") finalStep = "." + finalStep;
    else finalStep = "0" + finalStep;
  }

  return finalStep;
};

const DecimalQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  required,
  disabled,
  initialValue,
}) => {
  const [step, setStep] = useState("0.1");

  useEffect(() => {
    if (value && value.value) {
      if (String(value?.value).length !== 2) {
        setStep(createStep(value?.value));
      }
    } else {
      setStep("0.1");
    }
  }, [value]);

  return (
    <Grid>
      <FormControl>
        <FormLabel>{item.text}</FormLabel>
        <TextField
          type="number"
          value={value?.value || initialValue}
          inputProps={{ step: step, min: 0 }}
          onChange={(e) => {
            onChange(item, parseFloat(e.target.value));
          }}
          name="decimal-input-field"
          required={required}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
  );
};

export default DecimalQuestion;
