import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";

import { get, kebabCase } from "lodash";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

import TypeAheadInput from "../../../../../components/form/TypeAheadInput/TypeAheadInput";
import { useSelector } from "../../../../../store";

const ChoiceQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  required,
  disabled,
  initialValue,
  answerValueSet,
  isMultiple,
  onChangeNote,
  disableCloseOnSelect,
}) => {
  const options =
    useSelector((state) => state.options[item.answerValueSet]) ?? [];
  return (
    <>
      {options.length < 6 ? (
        <Grid>
          <FormControl>
            <FormLabel>{item.text}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={value?.value || initialValue}
              onChange={(e) => {
                onChange(item, e.target.value);
              }}
              name="radio-buttons-group"
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.code}
                  value={option.code}
                  control={<Radio disabled={disabled} />}
                  label={option.display}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : (
        <Grid>
          <FormControl fullWidth>
            <FormLabel required={required}>{item.text}</FormLabel>
            <TypeAheadInput
              key={`${item?.linkId}-${kebabCase(item?.text)}`}
              optionType={item.answerValueSet}
              value={value?.value || initialValue}
              onChange={(val) => {
                onChange(item, val);
              }}
              size={"medium"}
              fieldLabel={""}
              disabled={disabled}
              getValueLabel={(v) => {
                if (v.value) {
                  return v.value.display;
                }
                return get(v, "display");
              }}
              getOptionLabel={(o) => {
                if (o.value) {
                  return o.value.display;
                }
                return o.display;
              }}
              isMultiple={isMultiple}
              isOptionEqualToValue={(option, value) => {
                if (value && value.value) {
                  return option.code === value.value.code;
                }

                if (value && value.code) {
                  return option.code === value.code;
                }

                return option.code === value;
              }}
              disableCloseOnSelect={disableCloseOnSelect}
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
};

export default ChoiceQuestion;
