import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

const IntegerQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  required,
  disabled,
  initialValue,
}) => {
  return (
    <Grid>
      <FormControl>
        <FormLabel>{item.text}</FormLabel>
        <TextField
          type="number"
          value={value?.value || initialValue}
          onChange={(e) => onChange(item, e.target.value)}
          name="integer-input-field"
          required={required}
          disabled={disabled}
          inputProps={{ min: 0 }}
        />
      </FormControl>
    </Grid>
  );
};

export default IntegerQuestion;
