import { Feedback } from "../pages/health/encounters/cards/vitals/types/commonCDSTypes";

interface BPRange {
  systolic: { min: number; max: number };
  diastolic: { min: number; max: number };
  message: string;
  type: "success" | "warning" | "error";
}

const BP_RANGES: BPRange[] = [
  {
    systolic: { min: 0, max: 89 },
    diastolic: { min: 0, max: 59 },
    message: "Hypotension - Clinical evaluation needed",
    type: "error",
  },
  {
    systolic: { min: 90, max: 120 },
    diastolic: { min: 60, max: 80 },
    message: "Normal",
    type: "success",
  },
  {
    systolic: { min: 121, max: 129 },
    diastolic: { min: 60, max: 79 },
    message: "Elevated BP (Pre-hypertension)",
    type: "warning",
  },
  {
    systolic: { min: 130, max: 139 },
    diastolic: { min: 80, max: 89 },
    message: "High Blood Pressure (Stage 1)",
    type: "error",
  },
  {
    systolic: { min: 140, max: 179 },
    diastolic: { min: 90, max: 119 },
    message: "High Blood Pressure (Stage 2)",
    type: "error",
  },
  {
    systolic: { min: 180, max: 300 },
    diastolic: { min: 120, max: 200 },
    message: "Hypertensive Crisis - Seek Emergency Care",
    type: "error",
  },
];

export const inputHintHelper = {
  bp: (value: string): Feedback | undefined => {
    try {
      const [sys, dia] = value ? value.split("/").map(Number) : [NaN, NaN];

      if (isNaN(sys) || isNaN(dia)) {
        return undefined;
      }

      // Basic validation
      if (dia >= sys) {
        return {
          message:
            "Invalid: Diastolic pressure cannot be higher than or equal to systolic",
          type: "error",
        };
      }

      // Physiological range check
      if (sys < 60 || sys > 300 || dia < 30 || dia > 200) {
        return {
          message: "Values outside physiological range",
          type: "error",
        };
      }

      // Find matching range
      const matchingRange = BP_RANGES.find(
        (range) =>
          sys >= range.systolic.min &&
          sys <= range.systolic.max &&
          dia >= range.diastolic.min &&
          dia <= range.diastolic.max,
      );

      if (matchingRange) {
        return {
          message: matchingRange.message,
          type: matchingRange.type,
        };
      }

      // Edge case: Isolated Diastolic Hypertension (IDH) | e.g. 119/85 or 110/90
      if (sys < 120) {
        if (dia >= 81 && dia <= 89) {
          return {
            message: "Normal systolic with borderline diastolic pressure",
            type: "success",
          };
        }
        if (dia >= 90) {
          return {
            message:
              "Isolated Diastolic Hypertension (IDH) - Clinical evaluation needed",
            type: "error",
          };
        }
      }

      // Edge case: Elevated Diastolic | e.g. 120/85 or 120/90
      if (sys <= 120 && dia > 80) {
        if (dia >= 90) {
          return {
            message: "Elevated",
            type: "warning",
          };
        }
        return {
          message: "Stage 1 Hypertension (Elevated Diastolic)",
          type: "error",
        };
      }

      // Edge case: Isolated Systolic Hypertension (ISH) | e.g. 140/85 or 149/89
      if (sys >= 140 && dia < 90) {
        return {
          message:
            "Isolated Systolic Hypertension (ISH) - Clinical evaluation needed",
          type: "error",
        };
      }

      // Edge case: Elevated Systolic | e.g. 133/79
      if (sys >= 120 && sys <= 139 && dia < 80) {
        return {
          message: "Elevated systolic with normal diastolic - Monitor closely",
          type: "warning",
        };
      }

      // Edge case: Elevated BP with borderline diastolic | e.g.  129/85
      if (sys >= 120 && sys <= 129 && dia >= 80 && dia < 90) {
        return {
          message: "Elevated BP with borderline diastolic - Monitor closely",
          type: "warning",
        };
      }

      // Crisis systolic override
      if (sys >= 180) {
        return {
          message: "Hypertensive Crisis - Seek Emergency Care",
          type: "error",
        };
      }

      return {
        message: "Unusual BP combination - Verify measurement",
        type: "warning",
      };
    } catch (error) {
      console.error("Blood pressure validation error:", error);
      return undefined;
    }
  },
};