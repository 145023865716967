import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactElement, useState } from "react";
import { FeedbackType } from "../types/commonCDSTypes";
interface CdsArrowTooltipProps {
  type: FeedbackType;
  feedbackText: string;
  openCDS: boolean;
  children: ReactElement;
}

const CdsArrowTooltip: React.FC<CdsArrowTooltipProps> = ({
  type,
  feedbackText,
  openCDS = false,
  children,
}) => {
  let icon;
  let tooltipColor;
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const theme = useTheme();

  switch (type) {
    case "warning":
      icon = <WarningAmberIcon />;
      tooltipColor = theme.palette.warning.main;
      break;
    case "error":
      icon = <ErrorOutlineIcon />;
      tooltipColor = theme.palette.error.main;
      break;
    case "success":
    default:
      icon = <CheckCircleOutlineIcon />;
      tooltipColor = theme.palette.success.main;
      break;
  }
  const handleFocus = () => {
    setTooltipVisible(true);
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: tooltipColor,
          },
        },
        arrow: {
          sx: {
            color: tooltipColor,
          },
        },
      }}
      open={isTooltipVisible && openCDS}
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
          <Box sx={{ ml: 1 }}>{feedbackText}</Box>
        </Box>
      }
      arrow
    >
      {React.cloneElement(children, {
        onFocus: handleFocus,
        onBlur: () => setTooltipVisible(false),
      })}
    </Tooltip>
  );
};

export default CdsArrowTooltip;
