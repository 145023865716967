import { FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { bloodGlucoseTypes } from "../../../../../../slices/options/anatomy";
import {
  A1C_DISPLAY,
  calculateBloodGlucoseFeedback,
} from "../../../../../../utils/bloodGlucoseUtils";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";
import { testRegExp } from "../../../../../../utils/regex";
import { Feedback } from "../types/commonCDSTypes";
import { updateMetaData } from "../VitalsFormHelper";
import CdsArrowTooltip from "./cdsArrowTooltip";
import CdsFeedbackTextField from "./CdsFeedbackTextField";

export default function BloodGlucoseInput({
  value,
  disabled,
  size,
  encounter,
  patient,
  onChange,
}: VitalsInputProps) {
  const practitioner = usePractitioner();
  const [glucoseValue, setGlucoseValue] = useState(
    value?.valueQuantity?.value ?? "",
  );
  const [testType, setTestType] = useState(value?.method?.text ?? A1C_DISPLAY);
  const [feedback, setFeedback] = useState<Feedback>();
  const [unit, setUnit] = useState(
    value?.valueQuantity?.unit ?? (testType === A1C_DISPLAY ? "%" : "mg/dL"),
  );

  useEffect(() => {
    if (testType === A1C_DISPLAY) {
      setUnit("%");
    } else if (unit === "%") {
      setUnit("mg/dL");
    }
    if (glucoseValue) {
      handleGlucoseLevelChange(parseFloat(glucoseValue));
    }
  }, [testType, glucoseValue]);

  useEffect(() => {
    if (value?.valueQuantity?.value) {
      setGlucoseValue(value?.valueQuantity?.value);
    }
    if (value?.method?.text) {
      setTestType(value?.method?.text);
    }
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetValue = event.target.value ?? "";
      const valid = testRegExp(
        "non-negative decimal number or empty string",
        targetValue,
      );
      if (!valid) return;

      setGlucoseValue(targetValue);
      handleGlucoseLevelChange(parseFloat(targetValue));
      const newVital = {
        ...value,
        valueQuantity: {
          value: targetValue,
          unit,
        },
      };
      updateMetaData(newVital, practitioner);
      onChange(newVital);
    },
    [encounter, patient, unit, practitioner, onChange],
  );

  const getOptions = testType === A1C_DISPLAY ? ["%"] : ["mg/dL", "mmol/L"];

  const handleGlucoseLevelChange = useCallback(
    (glucoseValue: number, currentUnit: string = unit) => {
      setFeedback(
        calculateBloodGlucoseFeedback(glucoseValue, testType, currentUnit),
      );
    },
    [testType, unit],
  );

  const handleTestTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTestType = e.target.value;
      setTestType(newTestType);
      const currentValue = value?.valueQuantity?.value ?? glucoseValue;
      handleGlucoseLevelChange(parseFloat(currentValue));
      const newVital = {
        ...value,
        method: createCodeableConcept(
          CodingSystems.OBSERVATION_METHOD,
          bloodGlucoseTypes.find((c) => c.display.toLowerCase() === newTestType)
            ?.code,
          newTestType,
        ),
      };
      updateMetaData(newVital, practitioner);
      onChange(newVital);
    },
    [value, glucoseValue, handleGlucoseLevelChange, practitioner, onChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            id="vitals-blood-glucose-test"
            select
            label="Test Type"
            value={testType}
            disabled={disabled}
            onChange={handleTestTypeChange}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {bloodGlucoseTypes.map((c) => (
              <MenuItem value={c.display.toLowerCase()} key={c.code}>
                {c.display}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <CdsArrowTooltip
            type={feedback?.type}
            feedbackText={feedback?.message}
            openCDS={parseInt(value?.valueQuantity?.value, 10) >= 1}
          >
            <CdsFeedbackTextField
              feedbackType={glucoseValue ? feedback?.type : undefined}
              id="vitals-blood-glucose"
              value={glucoseValue}
              disabled={disabled}
              label="BGL"
              inputMode="numeric"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <SelectButton
                    label={unit}
                    options={getOptions}
                    variant="text"
                    disabled={disabled}
                    onSelect={(newUnit) => {
                      setUnit(newUnit);
                      const newVital = {
                        ...value,
                        valueQuantity: {
                          value: glucoseValue,
                          unit: newUnit,
                        },
                      };
                      updateMetaData(newVital, practitioner);
                      handleGlucoseLevelChange(
                        parseFloat(glucoseValue),
                        newUnit,
                      );
                      onChange(newVital);
                    }}
                    size={size}
                    getOptionLabel={(value) => value}
                  />
                ),
              }}
              size={size}
              variant="outlined"
              type="text"
              onChange={handleChange}
            />
          </CdsArrowTooltip>
        </FormControl>
      </Grid>
    </Grid>
  );
}
