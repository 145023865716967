import { Meta } from "./base";
import { CodeableConcept } from "./codeableConcept";
import { Reference } from "./reference";

export type MedicalEncounterStatus =
  | "planned"
  | "arrived"
  | "triaged"
  | "in-progress"
  | "on-leave"
  | "unknown"
  | "finished";

export interface MedicalEncounter {
  _id?: string;
  id?: string;
  subject?: Reference;
  class?: CodeableConcept;
  period?: Period;
  actualPeriod?: Period;
  status?: string;
  reasonCode?: any[];
  participant?: [];
  meta: Meta;
}

export type EncounterType = "simple" | "full" | "paper";

export const EncounterMode = {
  SIMPLE: "Simple Note",
  FULL: "Detailed Note",
  PAPER: "Paper Upload",
};
