import { FC } from "react";

const NotImplemented: FC = () => {
  return (
    <>
      <div>Not yet implemented</div>
    </>
  );
};

export default NotImplemented;
