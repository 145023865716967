import moment from "moment";
import { Vital } from "../../../../../types/Vitals";

export const vitalSignsCode = "8716-3";
export const vitalSignsCategory = "vital-signs";

class HeartRateCDS {
  static ranges = {
    neonate: { awake: [100, 205], sleeping: [90, 160] },
    infant: { awake: [100, 180], sleeping: [90, 160] },
    toddler: { awake: [98, 140], sleeping: [80, 120] },
    preschooler: { awake: [80, 120], sleeping: [65, 100] },
    schoolAge: { awake: [75, 118], sleeping: [58, 90] },
    adolescent: { awake: [60, 100], sleeping: [50, 90] },
    athlete: { awake: [40, 60], sleeping: null },
    adult: { awake: [60, 100], sleeping: null },
  };

  static ageGroups = [
    { value: "neonate", label: "Neonate" },
    { value: "infant", label: "Infant" },
    { value: "toddler", label: "Toddler" },
    { value: "preschooler", label: "Preschooler" },
    { value: "schoolAge", label: "School Age" },
    { value: "adolescent", label: "Adolescent" },
    { value: "adult", label: "Adult" },
    { value: "athlete", label: "Athlete" },
  ];

  static activities = [
    { value: "awake", label: "Awake" },
    { value: "sleeping", label: "Sleeping" },
  ];
}

export default HeartRateCDS;

/**
 * Shared CDS logic that can be used in multiple components
 * @class
 */
export class SharedCDS {
  static ageRanges = {
    neonate: {
      low: {
        value: 0,
        unit: "days",
      },
      high: {
        value: 28,
        unit: "days",
      },
    },
    infant: {
      low: {
        value: 29,
        unit: "days",
      },
      high: {
        value: 1,
        unit: "years",
      },
    },
    toddler: {
      low: {
        value: 2,
        unit: "years",
      },
      high: {
        value: 3,
        unit: "years",
      },
    },
    preschooler: {
      low: {
        value: 4,
        unit: "years",
      },
      high: {
        value: 5,
        unit: "years",
      },
    },
    schoolAge: {
      low: {
        value: 6,
        unit: "years",
      },
      high: {
        value: 12,
        unit: "years",
      },
    },
    adolescent: {
      low: {
        value: 13,
        unit: "years",
      },
      high: {
        value: 17,
        unit: "years",
      },
    },
    adult: {
      low: {
        value: 18,
        unit: "years",
      },
      high: {
        value: 999,
        unit: "years",
      },
    },
  };
  static getAgeRange = (age: number) => {
    if (age < 1) {
      return "neonate";
    }
    if (age < 2) {
      return "infant";
    }
    if (age < 4) {
      return "toddler";
    }
    if (age < 6) {
      return "preschooler";
    }
    if (age < 13) {
      return "schoolAge";
    }
    if (age < 18) {
      return "adolescent";
    }
    return "adult";
  };

  static initializeAgeGroup = (value: Vital, currentPatient) => {
    const patientAge = moment().diff(currentPatient?.birthDate, "years");
    if (value?.referenceRange?.[0]?.age) {
      const getAgeGroupFromValue = (value) => {
        const age = value?.referenceRange?.[0]?.age;
        if (age) {
          return Object.entries(this.ageRanges).find(
            ([, range]) =>
              age.low.value >= range.low.value &&
              age.high.value <= range.high.value &&
              age.low.unit === range.low.unit &&
              age.high.unit === range.high.unit,
          );
        }
        return null;
      };
      const ageGroup = getAgeGroupFromValue(value);
      return ageGroup?.[0] || "adult";
    } else if (currentPatient?.birthDate) {
      return this.getAgeRange(patientAge);
    } else {
      return "adult";
    }
  };
}
