import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { QuestionnaireItemProps } from ".";
import validator from "validator";

const UrlQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  initialValue,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (value && value.value) {
      checkUrl(`${value.value}`);
    } else {
      setErrorMessage(undefined);
    }
  });

  const checkUrl = (value) => {
    if (!validator.isURL(value)) {
      setErrorMessage("Invalid url: Format http:www.example.com");
    } else {
      setErrorMessage(undefined);
    }
  };

  const functionsCall = (e) => {
    onChange(item, e.target.value);
  };

  return (
    <Grid>
      <FormControl fullWidth>
        <FormLabel>{item.text}</FormLabel>
        <TextField
          variant="outlined"
          defaultValue={initialValue}
          value={value?.value || initialValue}
          onChange={functionsCall}
          disabled={disabled}
        ></TextField>
        <Typography color={"red"} variant="body2">
          {errorMessage}
        </Typography>
      </FormControl>
    </Grid>
  );
};

export default UrlQuestion;
