import { useState, useEffect, FC } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { get } from "lodash";
import { createCodeableConcept } from "../../utils/DataUtils";

type ScoreOption = {
  code: string;
  display: string;
  value: number;
};

const eyeOptions: ScoreOption[] = [
  { code: "LA6556-0", display: "Opens eyes spontaneously", value: 4 },
  { code: "LA6555-2", display: "Opens eyes in response to voice", value: 3 },
  { code: "LA6554-5", display: "Opens eyes in response to pain", value: 2 },
  { code: "LA6553-7", display: "No eye opening", value: 1 },
];

const motorOptions: ScoreOption[] = [
  { code: "LA6567-7", display: "Obeys commands", value: 6 },
  { code: "LA6566-9", display: "Localizing pain", value: 5 },
  { code: "LA6565-1", display: "Withdrawal from pain", value: 4 },
  { code: "LA6564-4", display: "Flexion to pain", value: 3 },
  { code: "LA6563-6", display: "Extension to pain", value: 2 },
  { code: "LA6562-8", display: "No motor response", value: 1 },
];

const verbalOptions: ScoreOption[] = [
  { code: "LA6561-0", display: "Oriented", value: 5 },
  { code: "LA6560-2", display: "Confused", value: 4 },
  { code: "LA6559-4", display: "Inappropriate words", value: 3 },
  { code: "LA6558-6", display: "Incomprehensible sounds", value: 2 },
  { code: "LA6557-8", display: "No verbal response", value: 1 },
];

const GCS_LOINC_CODES = {
  EYE: { code: "9267-6", display: "Glasgow coma score eye" },
  MOTOR: { code: "9268-4", display: "Glasgow coma score motor" },
  VERBAL: { code: "9270-0", display: "Glasgow coma score verbal" },
  //   TOTAL: "",
};

type GCSProps = {
  value: any;
  disabled?: boolean;
  onChange?: (value) => void;
};

export const GlasgowComaScale: FC<GCSProps> = ({
  value,
  disabled,
  onChange,
}: GCSProps) => {
  const [eyeScore, setEyeScore] = useState<string>("");
  const [motorScore, setMotorScore] = useState<string>("");
  const [verbalScore, setVerbalScore] = useState<string>("");
  const [totalScore, setTotalScore] = useState<number>(0);

  useEffect(() => {
    const calculateTotal = () => {
      const eye = eyeOptions.find((opt) => opt.code === eyeScore)?.value || 0;
      const motor =
        motorOptions.find((opt) => opt.code === motorScore)?.value || 0;
      const verbal =
        verbalOptions.find((opt) => opt.code === verbalScore)?.value || 0;
      setTotalScore(eye + motor + verbal);
    };
    calculateTotal();
  }, [eyeScore, motorScore, verbalScore]);

  useEffect(() => {
    if (value && Array.isArray(value.component)) {
      value.component?.forEach((component: any) => {
        const componentCode = get(component, "code.coding[0].code");
        const selectedValue = get(component, "value.coding[0].code");

        switch (componentCode) {
          case GCS_LOINC_CODES.EYE.code:
            setEyeScore(selectedValue);
            break;
          case GCS_LOINC_CODES.MOTOR.code:
            setMotorScore(selectedValue);
            break;
          case GCS_LOINC_CODES.VERBAL.code:
            setVerbalScore(selectedValue);
            break;
        }
      });
    }
  }, [value]);

  // Add this new function to handle score updates
  const handleScoreChange = (
    scoreType: "eye" | "motor" | "verbal",
    newValue: string,
  ) => {
    // Update the local state
    switch (scoreType) {
      case "eye":
        setEyeScore(newValue);
        break;
      case "motor":
        setMotorScore(newValue);
        break;
      case "verbal":
        setVerbalScore(newValue);
        break;
    }

    // Find the selected option
    const option = {
      eye: eyeOptions.find((opt) => opt.value === Number(newValue)),
      motor: motorOptions.find((opt) => opt.value === Number(newValue)),
      verbal: verbalOptions.find((opt) => opt.value === Number(newValue)),
    }[scoreType];

    if (!option) return;

    // Create updated observation
    const updatedObservation = { ...value };
    const componentIndex = updatedObservation.component.findIndex(
      (comp: any) =>
        comp.code.coding[0].code ===
        GCS_LOINC_CODES[scoreType.toUpperCase()].code,
    );

    const newCoding = [
      {
        system: "http://example.org/codes",
        code: option.value.toString(),
        display: option.display,
      },
      {
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/itemWeight",
            valueDecimal: option.value,
          },
        ],
        system: "http://loinc.org",
        code: option.code,
        display: option.display,
      },
    ];

    if (componentIndex !== -1) {
      updatedObservation.component[componentIndex].value.coding = newCoding;
    } else {
      updatedObservation.component.push({
        code: createCodeableConcept(
          "http://loinc.org",
          GCS_LOINC_CODES[scoreType.toUpperCase()].code,
          GCS_LOINC_CODES[scoreType.toUpperCase()].display,
        ),
        value: {
          coding: newCoding,
          text: `${option.value} (${option.display})`,
        },
      });
    }

    // Update the total score
    const eye =
      eyeOptions.find(
        (opt) =>
          opt.value === Number(scoreType === "eye" ? newValue : eyeScore),
      )?.value || 0;
    const motor =
      motorOptions.find(
        (opt) =>
          opt.value === Number(scoreType === "motor" ? newValue : motorScore),
      )?.value || 0;
    const verbal =
      verbalOptions.find(
        (opt) =>
          opt.value === Number(scoreType === "verbal" ? newValue : verbalScore),
      )?.value || 0;
    const newTotal = eye + motor + verbal;

    updatedObservation.value.value = newTotal;

    onChange(updatedObservation);
  };

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="subtitle1">Glasgow Coma Scale</Typography>
        <Typography variant="subtitle1" sx={{ ml: 2 }}>
          Score: {get(value, "value.value")}
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <TextField
            label="Eyes"
            size="small"
            value={eyeScore}
            select
            disabled={disabled}
            onChange={(e) => handleScoreChange("eye", e.target.value)}
          >
            {eyeOptions.map((option) => (
              <MenuItem key={option.code} value={option.value}>
                {option.display} ({option.value})
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl sx={{ flex: 1 }}>
          <TextField
            label="Motor"
            title="Motor"
            size="small"
            select
            disabled={disabled}
            value={motorScore}
            // InputLabelProps={{ shrink: true }}
            onChange={(e) => handleScoreChange("motor", e.target.value)}
          >
            {motorOptions.map((option) => (
              <MenuItem key={option.code} value={option.value}>
                {option.display} ({option.value})
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl sx={{ flex: 1 }}>
          <TextField
            size="small"
            select
            // InputLabelProps={{ shrink: true }}
            label="Verbal"
            disabled={disabled}
            value={verbalScore}
            onChange={(e) => handleScoreChange("verbal", e.target.value)}
          >
            {verbalOptions.map((option) => (
              <MenuItem key={option.code} value={option.value}>
                {option.display} ({option.value})
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Stack>
    </Box>
  );
};
