export const createDefaultGlasgowComaScaleObservation = () => {
  const observation = {
    status: "final",
    code: {
      coding: [
        {
          system: "http://loinc.org",
          code: "9269-2",
          display: "Glasgow coma score total",
        },
      ],
      text: "Glasgow Coma Scale (GCS)",
    },
    value: {
      //   value: 10,
      system: "http://unitsofmeasure.org",
      code: "{score}",
    },
    component: [
      //   {
      //     code: {
      //       coding: [
      //         {
      //           system: "http://loinc.org",
      //           code: "9268-4",
      //           display: "Glasgow coma score motor",
      //         },
      //       ],
      //       text: "GCS Motor",
      //     },
      //     value: {
      //       coding: [
      //         {
      //           system: "http://example.org/codes",
      //           code: "5",
      //           display: "Localizes painful stimuli",
      //         },
      //         {
      //           extension: [
      //             {
      //               url: "http://hl7.org/fhir/StructureDefinition/itemWeight",
      //               valueDecimal: 5,
      //             },
      //           ],
      //           system: "http://loinc.org",
      //           code: "LA6566-9",
      //           display: "Localizing pain",
      //         },
      //       ],
      //       text: "5 (Localizes painful stimuli)",
      //     },
      //   },
      //   {
      //     code: {
      //       coding: [
      //         {
      //           system: "http://loinc.org",
      //           code: "9270-0",
      //           display: "Glasgow coma score verbal",
      //         },
      //       ],
      //       text: "GSC Verbal",
      //     },
      //     value: {
      //       coding: [
      //         {
      //           system: "http://example.org/codes",
      //           code: "4",
      //           display: "Confused, disoriented",
      //         },
      //         {
      //           extension: [
      //             {
      //               url: "http://hl7.org/fhir/StructureDefinition/itemWeight",
      //               valueDecimal: 4,
      //             },
      //           ],
      //           system: "http://loinc.org",
      //           code: "LA6560-2",
      //           display: "Confused",
      //         },
      //       ],
      //       text: "4 (Confused, disoriented)",
      //     },
      //   },
      //   {
      //     code: {
      //       coding: [
      //         {
      //           system: "http://loinc.org",
      //           code: "9267-6",
      //           display: "Glasgow coma score eye opening",
      //         },
      //       ],
      //       text: "Eyes",
      //     },
      //     value: {
      //       coding: [
      //         {
      //           system: "http://example.org/codes",
      //           code: "4",
      //           display: "Opens eyes spontaneously",
      //         },
      //         {
      //           extension: [
      //             {
      //               url: "http://hl7.org/fhir/StructureDefinition/itemWeight",
      //               valueDecimal: 4,
      //             },
      //           ],
      //           system: "http://loinc.org",
      //           code: "LA6556-0",
      //           display: "Eyes open spontaneously",
      //         },
      //       ],
      //       text: "4 (Opens eyes spontaneously)",
      //     },
      //   },
    ],
  };
  return observation;
};
