import moment from "moment";

const formater3 = "MMM D, YYYY";
const formater7 = "YYYY";
const formater8 = "MMM";
const formater9 = "D";
const formater10 = "MM-DD-YYYY";
const formater13 = "hh:mma";
const formater14 = "D MMM";
const formater15 = "D MMM, YYYY";
const formater16 = "MM-DD-YYYY";
const formater17 = "HH:MM";
const dateTimeformat = "DD-MMM-YYYY, LT";
const formatMmmDdYyyy = "DD-MMM-YYYY";
const ChartDate = "DD/MM/YY";
const formatTimeDateFully = "dddd, MMMM DD YYYY, hh:mm A";
const TODAY_DATE = new Date();
const MIN_DATE = new Date("1900-01-01");

function checkInvalidDate(date) {
  return `${date}`.includes("Invalid") ? "" : date;
}

export const isValidDate = (date) => {
  const parsedDate = Date.parse(date);
  if (new Date(parsedDate).toString() === "Invalid Date") {
    return false;
  }

  return !isNaN(parsedDate);
};

export function formatMMM_do_YYYY(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater3));
}

export function formatMMM_Year(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater7));
}

export function formatMMM_Month(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater8));
}

export function formatMMM_date(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater9));
}

export function formatMMM_YYYY(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater10));
}

export function formatMMM_time(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater13));
}

export function formatMMM_day(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater14));
}

export function formatMMM_Date_day(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater14));
}

export function formatMMM_dashboard(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater15));
}

export function formatMMM_birthday(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater16));
}

export function formatMMM_24Hours(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formater17));
}

export function formatDateTime(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(dateTimeformat));
}

export function formatDateTimeFully(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formatTimeDateFully));
}

export function formatDefaultDate(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(formatMmmDdYyyy));
}
export function formatChartDate(timedateObj) {
  return checkInvalidDate(moment(timedateObj).format(ChartDate));
}

export const formatDateForUrl = (date: Date) => {
  if (!date) {
    return undefined;
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}/${day}/${year}`;
};

export const formatDateTimeForUrl = (date: Date) =>
  `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

export function isStartBeforeEndDate(start, end) {
  if (!start || !end) {
    return true;
  }
  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);
  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);
  return new Date(endDate).getTime() >= new Date(startDate).getTime();
}

export function minutesBetween(start: Date, end: Date) {
  if (start && end) {
    try {
      const minutes = Math.ceil(
        Math.abs(new Date(end).getTime() - new Date(start).getTime()) /
          (1000 * 60),
      );
      return minutes;
    } catch {
      console.log(`start or end are not dates: start: ${start}, end: ${end}`);
      return 0;
    }
  }
  return 0;
}

export const generateIntervalTimeList = (interval) => {
  const timeList = [];
  const startTime = moment("00:00", "HH:mm:ss");
  const endTime = moment("24:00", "HH:mm:ss");

  while (startTime.isBefore(endTime)) {
    const time = startTime.format("HH:mm:ss");
    const timeLabel = startTime.format("hh:mm A");
    timeList.push({ value: time, label: timeLabel });
    startTime.add(interval, "minutes");
  }
  return timeList;
};

export const datePickerErrorMsg = (
  reason: string,
  minDate?: Date,
  maxDate?: Date,
): string => {
  switch (reason) {
    case "invalidDate":
      return `Invalid date format`;
    case "disableFuture":
      return `Date can't be in the future`;
    case "minDate":
      return `Date can't be before ${
        minDate ? formatMMM_do_YYYY(minDate) : formatMMM_do_YYYY(MIN_DATE)
      }`;
    case "maxDate":
      return `Date can't be after ${
        maxDate ? formatMMM_do_YYYY(maxDate) : formatMMM_do_YYYY(TODAY_DATE)
      }`;
    default:
      return "";
  }
};

/**
 * Calculates the patient's age at the time of encounter
 * @param birthDate - Patient's date of birth
 * @param encounterDate - Date when the encounter was created
 * @returns String representing the patient's age at the time of encounter
 */

export const displayAgeAtEncounter = (
  birthDate: string | Date,
  encounterDate: string | Date,
): string => {
  const presentEncounterAge = displayAge(birthDate, encounterDate);
  return presentEncounterAge ?? "";
};

/**
 * Calculates the patient's present age to date
 * @param birthDate - Patient's date of birth
 * @param startDate - Date at which the age is to be calculated, defaults to today's date
 * @returns String representing the patient's age
 */
export const displayAge = (
  birthDate: string | Date,
  startDate: string | Date = TODAY_DATE,
): string | undefined => {
  try {
    const birth = new Date(birthDate);
    const start = new Date(startDate);

    if (!isValidDate(birth) || !isValidDate(start)) {
      return undefined;
    }

    const { years, months } = calculateAgeDifference(birth, start);
    return formatAgeString(years, months);
  } catch (error) {
    console.error("Error calculating age at encounter:", error);
    return undefined;
  }
};

const calculateAgeDifference = (birth: Date, start: Date) => {
  let years = start.getFullYear() - birth.getFullYear();
  let months = start.getMonth() - birth.getMonth();

  // Adjust for month/day differences
  if (months < 0 || (months === 0 && start.getDate() < birth.getDate())) {
    years--;
    months += 12;
  }

  // Adjust months if day of month is earlier
  if (start.getDate() < birth.getDate()) {
    months--;
  }

  // Ensure months stays within 0-11 range
  months = ((months % 12) + 12) % 12;

  return { years, months };
};

const pluralize = (count: number, noun: string, suffix = "s"): string =>
  `${count} ${noun}${count === 1 ? "" : suffix}`;

const formatAgeString = (years: number, months: number): string => {
  const isBelowTwoYears = years < 2;
  if (isBelowTwoYears) {
    return years === 0
      ? pluralize(months, "month")
      : `${pluralize(years, "year")}, ${pluralize(months, "month")}`;
  } else {
    return pluralize(years, "year");
  }
};

export const calculateDuration = (start, end) => {
  if (!start || !end) {
    return "";
  }
  // Calculate the difference in milliseconds
  const difference = Math.abs(end - start);

  // Convert milliseconds to minutes, hours, days, months, and years
  const minutes = Math.floor(difference / (1000 * 60));
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30.44));
  const years = Math.floor(difference / (1000 * 60 * 60 * 24 * 30.44 * 12));

  // Return the smallest unit of difference
  if (minutes < 120) {
    return `${minutes} minute(s)`;
  } else if (hours < 24) {
    return `${hours} hour(s)`;
  } else if (days < 30.44) {
    return `${days} day(s)`;
  } else if (months < 12) {
    return `${months} month(s)`;
  } else {
    return `${years} year(s)`;
  }
};

/**
 * Joins given date and time into a single date object
 * @param {date} date - the date object
 * @param {date} time - the date object with the time
 * @returns {date} the combined date object
 * @example combineDateTime(new Date('2005-08-01'), new Date('2023-09-01T10:00:00')) // returns new Date('2005-08-01T10:00:00')
 */
export const combineDateTime = (date: Date, time: Date) => {
  const currentDate = new Date(date);
  const currentTime = new Date(time);

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentTime.getHours(),
    currentTime.getMinutes(),
    currentTime.getSeconds(),
  );
};
