import { memo, useEffect, useState } from "react";

import { cloneDeep, debounce, flatten } from "lodash";
import {
  QuestionnaireItem,
  QuestionnaireItemTypes,
  createResponseItem,
} from "./QuestionnaireItemTypes";

type Props = {
  item: QuestionnaireItem;
  answers: Record<string, any>;
  notes: Record<string, any>;
  isDisabled: boolean;
  enabledWhenDeps?: Record<string, any>;
  onItemChange: (
    item: QuestionnaireItem,
    answer: any[],
    allowModify?: boolean,
  ) => void;
  onNotesChange: (item: QuestionnaireItem, note) => void;
  onEnabledChange?: (text: string, enabled: boolean) => void;
  enableGroupNote?: boolean;
};

function QuestionnaireGroup({
  item,
  answers: initialAnswers,
  notes: initialNotes,
  isDisabled,
  onItemChange,
  onNotesChange: notesChangeCallback,
  enabledWhenDeps,
  onEnabledChange,
  enableGroupNote,
}: Props) {
  const [answers, setAnswers] = useState(cloneDeep(initialAnswers));
  const [notes, setNotes] = useState(cloneDeep(initialNotes));
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (!item.enableWhen) return;

    const enabledWhen = Array.isArray(item.enableWhen)
      ? item.enableWhen
      : [item.enableWhen];

    const enabled = enabledWhen[
      item.enableBehavior === "all" ? "every" : "some"
    ]((condition) => {
      const deps = enabledWhenDeps[condition.question];
      if (!deps) return false;

      if (condition.operator === "=") {
        const depCodes = deps.map((e) => e.value?.code);
        return depCodes.includes(condition.answer);
      }

      return false;
    });

    // if not enabled, remove answers and notes

    if (!enabled) {
      const newValue = ["multi-choice", "group"].includes(item.type)
        ? []
        : undefined;

      onItemChange(item, newValue, true);
      setAnswers({});
      setNotes({});
    }

    setEnabled(enabled);
  }, [enabledWhenDeps]);

  useEffect(() => {
    if (onEnabledChange) onEnabledChange(item.text, enabled);
  }, [enabled]);

  const debouncedOnNotesChangeCallback = debounce(notesChangeCallback, 0);
  const onNotesChange = (note, item: QuestionnaireItem) => {
    setNotes({
      ...notes,
      [item.linkId]: note,
    });
    debouncedOnNotesChangeCallback(item, note);
  };

  const QuestionnaireComponent = item.type
    ? QuestionnaireItemTypes[item.type]
    : QuestionnaireItemTypes["question"];

  if (!enabled) return <></>;

  return (
    <QuestionnaireComponent
      item={item}
      onChange={(item, newAnswers) => {
        setAnswers({
          ...answers,
          [item.linkId]: createResponseItem(item, newAnswers).answer,
        });
        onItemChange(item, newAnswers);
      }}
      answers={answers}
      isMultiple={true}
      enableGroupNote={enableGroupNote}
      notes={notes}
      disabled={isDisabled}
      onChangeNote={onNotesChange}
    />
  );
}

const MemoizedQuestionnaireGroup = memo(
  QuestionnaireGroup,
  (prevProps, nextProps) => {
    const isDepsSame = (() => {
      try {
        const prevDeps = prevProps.enabledWhenDeps;
        const nextDeps = nextProps.enabledWhenDeps;

        if (!prevDeps && !nextDeps) return true;
        if (!prevDeps || !nextDeps) return false;

        const prevValues = flatten(Object.values(prevDeps)).filter((e) => e);
        const nextValues = flatten(Object.values(nextDeps)).filter((e) => e);

        const prevCodes = prevValues.map((e) => e.value?.code);
        const nextCodes = nextValues.map((e) => e.value?.code);

        if (prevCodes.length !== nextCodes.length) return false;

        return prevCodes.every((code) => nextCodes.includes(code));
      } catch (error) {
        console.error(error);
        return true;
      }
    })();

    const isSame =
      prevProps.item.linkId === nextProps.item.linkId &&
      prevProps.isDisabled === nextProps.isDisabled &&
      isDepsSame;

    return isSame;
  },
);

export default MemoizedQuestionnaireGroup;
