import { Feedback } from "../pages/health/encounters/cards/vitals/types/commonCDSTypes";

export const temperatureRanges = {
  rectal: {
    fahrenheit: { normal: [97.9, 100.4], fever: [100.5, Infinity] },
    celcius: { normal: [36.6, 38.0], fever: [38.1, Infinity] },
  },
  tympanic: {
    fahrenheit: { normal: [96.4, 100.4], fever: [100.5, Infinity] },
    celcius: { normal: [35.8, 38.0], fever: [38.1, Infinity] },
  },
  oral: {
    fahrenheit: { normal: [95.9, 99.5], fever: [99.6, Infinity] },
    celcius: { normal: [35.5, 37.5], fever: [37.6, Infinity] },
  },
  axillary: {
    fahrenheit: { normal: [97.7, 99.5], fever: [99.6, Infinity] },
    celcius: { normal: [36.5, 37.5], fever: [37.6, Infinity] },
  },
  generic: {
    fahrenheit: {
      normal: [97.8, 99.1],
      lowGrade: [98.6, 100.4],
      fever: [100.5, Infinity],
    },
    celcius: {
      normal: [36.5, 37.3],
      lowGrade: [37.0, 38.0],
      fever: [38.1, Infinity],
    },
  },
} as const;

export function calculateTemperatureFeedback(
  tempValue: number,
  method: string,
  unit: string,
): Feedback {
  if (Number.isNaN(tempValue)) {
    return { message: "Invalid temperature", type: "error" };
  }

  const range = temperatureRanges[method]?.[unit];
  if (!range) {
    return {
      message: "No range defined for this method/unit combination",
      type: "warning",
    };
  }

  if (tempValue >= range.normal[0] && tempValue <= range.normal[1]) {
    return { message: "Normal temperature", type: "success" };
  }

  if (
    range.lowGrade &&
    tempValue >= range.lowGrade[0] &&
    tempValue <= range.lowGrade[1]
  ) {
    return { message: "Low-grade fever", type: "warning" };
  }

  if (tempValue >= range.fever[0]) {
    return { message: "Fever", type: "error" };
  }

  return { message: "Below normal range", type: "warning" };
}
