import { FC } from "react";
import { Grid, Typography, Skeleton } from "@mui/material";
import { kebabCase } from "lodash";
import { QuestionnaireItem } from "../../pages/health/encounters/components/QuestionnaireItemTypes";
import QuestionnaireGroup from "../../pages/health/encounters/components/QuestionnaireSection";

interface HospitalIntakeProps {
  assessment: Record<string, any>;
  answers: Record<string, any>;
  onItemChange: (
    item: QuestionnaireItem,
    answer: any[],
    allowModify?: boolean,
  ) => void;
  disabled?: boolean;
  loading?: boolean;
}

const HospitalIntakeQuestionnaire: FC<HospitalIntakeProps> = ({
  assessment,
  answers,
  onItemChange,
  disabled,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Grid container>
          <Grid item xs={12} key={`loading-triage-Assessment`} mt={1}>
            <Typography variant="subtitle2">
              {" "}
              Loading Triage Assessment...
            </Typography>
          </Grid>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={12} key={`loading-time-${index}`} mt={1}>
              <Skeleton variant="rounded" height={36} animation="pulse" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container>
          {assessment?.item?.map((item, i: number) => {
            // Extract answers for this item
            const itemAnswers = Object.fromEntries(
              Object.entries(answers).filter(([key]) => {
                return key.startsWith(`${item.linkId}.`);
              }),
            );

            return (
              <Grid key={`${item.linkId}-${i}`} item xs={12}>
                <div
                  id={`hospital-intake-assessment-${kebabCase(item.text)}`}
                />
                <QuestionnaireGroup
                  item={item}
                  onItemChange={onItemChange}
                  answers={itemAnswers}
                  isDisabled={disabled}
                  enableGroupNote={false}
                  notes={[]}
                  onNotesChange={() => {}}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default HospitalIntakeQuestionnaire;
