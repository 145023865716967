import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import type { FC } from "react";
import OrganizationLookup from "../../../facility/components/OrganizationLookup";
import { get, set, unionBy } from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Restriction } from "../../../../types/restriction";
import PractitionerLookup from "../../../facility/components/PractitionerLookup";
import { useOrganizationReference } from "../../../facility/hooks/useOrganization";
import usePractitioner from "../../../../hooks/usePractitioner";
import { ExpandMore } from "@mui/icons-material";

interface RestrictionInputInterface {
  restriction: Restriction;
  disabled?: boolean; //this is to disable updating restrictions on an object.
}

const Restrictions: FC<RestrictionInputInterface> = ({
  restriction,
  disabled,
}) => {
  const { enablePractitionerRestrictions, enableOrganizationRestrictions } =
    useFlags();
  const currentPractitionerRef = usePractitioner();
  const currentOrgReference = useOrganizationReference();
  const [practitionersDisabled, setPractitionersDisabled] = useState(true);
  const [organizationsDisabled, setOrganizationsDisabled] = useState(true);

  useEffect(() => {
    if (restriction?.value) {
      const practionersRestricted =
        get(restriction, "value.forPractitioners") === true;

      if (!practionersRestricted) {
        setPractitionersDisabled(true);
        if (get(restriction, "value.practitioners")?.length > 0) {
          restriction.onUpdate({ ...restriction.value, practitioners: [] });
        }
      } else if (practionersRestricted) {
        if (
          !get(restriction, "value.practitioners") ||
          get(restriction, "value.practitioners").length < 1
        ) {
          restriction.onUpdate({
            ...restriction.value,
            practitioners: [currentPractitionerRef],
          });
        }
        setPractitionersDisabled(false);
      }

      const orgsRestricted =
        get(restriction, "value.forOrganizations") === true;

      if (!orgsRestricted) {
        setOrganizationsDisabled(true);
        if (get(restriction, "value.organizations")?.length > 0) {
          restriction.onUpdate({ ...restriction.value, organizations: [] });
        }
      } else if (orgsRestricted) {
        if (
          !get(restriction, "value.organizations") ||
          get(restriction, "value.organizations")?.length < 1
        ) {
          restriction.onUpdate({
            ...restriction.value,
            organizations: [currentOrgReference],
          });
        }
        setOrganizationsDisabled(false);
      }
    }
  }, [currentOrgReference, currentPractitionerRef, restriction]);

  const updateRestriction = (field, value) => {
    const copy = { ...restriction.value };
    set(copy, field, value);
    restriction.onUpdate(copy);
  };

  return (
    <>
      <Grid container px={4} sx={restriction?.style}>
        <Grid item xs={12}>
          <Accordion
            style={{
              border: "0px",
              width: "100%",
              marginTop: 15,
              marginLeft: "auto",
              marginRight: "auto",
              paddingBottom: 10,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Restricted To</Typography>{" "}
            </AccordionSummary>
            <Grid container spacing={2} px={2}>
              {enablePractitionerRestrictions && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      md={2}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        style={{ paddingTop: 7 }}
                        label="People"
                        disabled={disabled}
                        control={
                          <Checkbox
                            checked={
                              get(restriction, "value.forPractitioners") ||
                              false
                            }
                            onChange={() => {
                              updateRestriction(
                                "forPractitioners",
                                !get(restriction, "value.forPractitioners"),
                              );
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={10} xs={12}>
                      <PractitionerLookup
                        label="Select other practitioners that should have access to this record."
                        disabled={practitionersDisabled || disabled}
                        multiple={true}
                        managingOrganization={
                          currentOrgReference?.identifier as string
                        }
                        value={get(restriction, "value.practitioners")}
                        onChange={(updatedPractitioners) => {
                          updateRestriction(
                            "practitioners",
                            unionBy(
                              updatedPractitioners,
                              [currentPractitionerRef],
                              (r) => r.identifier,
                            ),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {enableOrganizationRestrictions && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      md={2}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        style={{ paddingTop: 7 }}
                        label="Facilities"
                        control={
                          <Checkbox
                            disabled={disabled}
                            checked={get(restriction, "value.forOrganizations")}
                            onChange={() => {
                              updateRestriction(
                                "forOrganizations",
                                !get(restriction, "value.forOrganizations") ||
                                  false,
                              );
                            }}
                          />
                        }
                      />
                    </Grid>
                    {/* Implementation yet to be completed */}
                    <Grid item md={10} xs={12}>
                      <OrganizationLookup
                        label="Select facilities that should have access to this record."
                        disabled={organizationsDisabled || disabled}
                        multiple={true}
                        value={get(restriction, "value.organizations")}
                        onChange={(updatedOrgs) => {
                          updateRestriction(
                            "organizations",
                            unionBy(
                              updatedOrgs,
                              [currentOrgReference],
                              (r) => r.identifier,
                            ),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Accordion>
          {/* <Typography variant="subtitle2">Restrictions</Typography>
          <Typography variant="body2">
            Would you like toplace restrictions on this object
          </Typography> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Restrictions;
