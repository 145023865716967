import TextField from "@mui/material/TextField";
import { FormControl, FormLabel, Grid } from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";
import { DateTimePicker } from "@mui/x-date-pickers";

const DateTimeQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  initialValue,
  disabled,
  required,
}) => {
  return (
    <Grid>
      <FormControl>
        <FormLabel required={required}>{item.text}</FormLabel>
        <DateTimePicker
          value={value?.value || initialValue}
          onChange={(value) => onChange(item, value)}
          renderInput={(params) => <TextField size="small" {...params} />}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
  );
};

export default DateTimeQuestion;
