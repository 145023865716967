// eslint-disable-next-line import/prefer-default-export
export enum QueryKeys {
  ALLERGIES = "allergies",
  APPOINTMENT_DUPLICATE_PATIENTS = "appointment-duplicate-patients",
  APPOINTMENTS = "appointments",
  APPOINTMENTS_BY_TYPE_PENDING = "appointments-type-pending",
  APPOINTMENTS_BY_TYPE_WAITLISTED = "appointments-type-waitlisted",
  BROADCASTS = "broadcasts",
  CARE_PLANS = "care_plans",
  COUNT_ENTITIES = "count-entities",
  ENCOUNTERS = "encounters",
  MEDICATION = "medication-requests",
  MEDICATION_ADMIN_REQUEST = "medication-adminstration-requests",
  MEDICATION_ADMINISTRATION = "medication-adminstrations",
  MEDICATION_REQUEST_ENCOUNTER = "medication-request-encounters",
  OBGYN_QUESTIONNAIRE_HISTORY = "obgyn-questionnaire-history",
  PATIENTS = "patients",
  PRACTITIONERS = "practitioners",
  PREVIOUS_ENCOUNTERS = "previous_encounters",
  SERVICES = "services",
  VITALS = "vitals",
}
