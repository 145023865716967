import { FC } from "react";
import { QuestionnaireItemProps } from ".";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "../../../../../store";

const getWNLDisplayText = (category: string) => {
  switch (category) {
    case "generalPhysicalAssessment":
      return "Normal general appearance. No apparent distress (NAD).";
    case "headPhysicalAssessment":
      return "Normocephalic, atraumatic, (NCAT).";
    case "eyesPhysicalAssessment":
      return "Pupils equal, round, reactive to light and accommodation (PERRLA). Red reflex present bilaterally. Light reflex symmetric. Extra-ocular eye movement intact (EOMI), with no strabismus.";
    case "earsPhysicalAssessment":
      return "Normal external ears, normal tympanic membranes (TMs).";
    case "nosePhysicalAssessment":
      return "Normal nares.";
    case "oralCavityPhysicalAssessment":
      return "Mucosal membranes moist (MMM). Normal gums, mucosa, palate. Good definition.";
    case "neckPhysicalAssessment":
      return "Supple, with no masses. Full range of movements (ROM).";
    case "cardiovascularPhysicalAssessment":
      return "Regular rate and rythm (RRR). No murmurs/rubs/gallops (m/r/g). Jugular vein (JV) non-distended. Good capillary refill.";
    case "respiratoryPhysicalAssessment":
      return "Lungs clear to auscultation bilaterally (CTAB), no wheezes/rhonchi/crackles (w/r/c).";
    case "abdomenPhysicalAssessment":
      return "Soft, non-tender, non-distended (NT/ND), normal bowel sounds (NBS), no masses or organomegaly.";
    case "maleGenitaliaPhysicalAssessment":
    case "femaleGenitaliaPhysicalAssessment":
      return "Normal genitalia.";
    case "skinPhysicalAssessment":
      return "Warm and well perfused. No skin rashes or abnormal lesions.";
    case "musculoskeletalPhysicalAssessment":
      return "Normal extremities. No deformities. Normal gait. No clubbing, cyanosis, or oedema. Full range of movements (ROM).";
    case "neurologicalPhysicalAssessment":
      return "Normal muscle strength and tone. No focal deficits. Cranial nerves II-XII intact.";
    case "chestPhysicalAssessment":
      return "No chest deformity, asymmetry. Normal contours. No nodules, masses, tenderness, or axillary adenopathy. No nipple discharge.";
    case "psychiatricPhysicalAssessment":
      return "Appropriate mood and affect. Good judgement and insight. No visual or auditory hallucinations. No suicidal or homicidal ideation.";
    case "digitalRectalPhysicalAssessment":
      return "Normal rectal sphincter tone. No external masses or lesions. Stool is normal in appearance, guac negative.";
    case "lymphaticPhysicalAssessment":
      return "No lymph nodes palpable in the head and neck, no swelling, no asymmetry, no discolouration or increased temperature over lymph node locations, and no pain.";
    case "backPhysicalAssessment":
      return "Curvature of the cervical, thoracic, and lumbar spine are within normal limits. No tenderness is noted on palpation of the spinous processes.";
    default:
      return "Within normal limits (WNL).";
  }
};

const MultiChoiceQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  disabled,
}) => {
  const options =
    useSelector((state) => state.options[item.answerValueSet]) ?? [];
  const hasVariance = options.some((e) => e.variant);
  const hasNeutral =
    hasVariance && options.some((e) => e.variant && e.variant === "neutral");

  const onCheckboxChange = (event, element) => {
    if (["WNL", "LA6630-3"].includes(element.code)) {
      if (event.target.checked) {
        onChange(item, [element]);
      } else {
        onChange(item, []);
      }
      return;
    }

    if (event.target.checked) {
      onChange(item, [...(value ?? []), element]);
    } else {
      const filteredArr = value.filter(
        (elem) => elem.value.code !== element.code,
      );
      onChange(item, filteredArr);
    }
  };

  const isChecked = (element) =>
    (value ?? []).some((elem) => {
      return elem.value.code === element.code;
    });

  const isWNLChecked = (value ?? []).some((elem) => {
    return elem.value.code === "WNL";
  });

  const isNotExaminedChecked = (value ?? []).some((elem) => {
    return elem.value.code === "LA6630-3";
  });

  return (
    <div>
      <Grid id={`multi-choice-${item.answerValueSet}`} container mb={3}>
        {!hasVariance ? (
          options.map((e, i) => {
            return (
              <Grid item xs={6} key={`question-option-${i}`}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={isChecked(e)}
                        onChange={(event) => onCheckboxChange(event, e)}
                      />
                    }
                    label={e.display}
                  />
                </FormGroup>
              </Grid>
            );
          })
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Normal
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disabled}
                              checked={isWNLChecked}
                              onChange={(event) =>
                                onCheckboxChange(event, {
                                  code: "WNL",
                                  display: getWNLDisplayText(
                                    item.answerValueSet,
                                  ),
                                  variant: "positive",
                                })
                              }
                            />
                          }
                          label="Within normal limits (WNL)"
                        />
                      </FormGroup>
                    </Grid>
                    <Divider
                      sx={{
                        borderColor: "rgba(0, 0, 0, 0.12)",
                        width: "100%",
                        height: 1,
                        my: 1,
                      }}
                    />
                    {options
                      .filter((e) => e.variant === "positive")
                      .map((e, i) => {
                        return (
                          <Grid item xs={4} key={`positive-variant-${i}`}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={
                                      disabled ||
                                      isWNLChecked ||
                                      isNotExaminedChecked
                                    }
                                    checked={isChecked(e)}
                                    onChange={(event) =>
                                      onCheckboxChange(event, e)
                                    }
                                  />
                                }
                                label={e.display}
                              />
                            </FormGroup>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Abnormal
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {options
                      .filter((e) => e.variant === "negative")
                      .map((e, i) => {
                        return (
                          <Grid item xs={4} key={`negative-variant-${i}`}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={
                                      disabled ||
                                      isWNLChecked ||
                                      isNotExaminedChecked
                                    }
                                    checked={isChecked(e)}
                                    onChange={(event) =>
                                      onCheckboxChange(event, e)
                                    }
                                  />
                                }
                                label={e.display}
                              />
                            </FormGroup>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {hasNeutral && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold" }}>Other</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {options
                        .filter((e) => e.variant === "neutral")
                        .map((e, i) => {
                          return (
                            <Grid item xs={4} key={`neutral-variant-${i}`}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        disabled ||
                                        isWNLChecked ||
                                        isNotExaminedChecked
                                      }
                                      checked={isChecked(e)}
                                      onChange={(event) =>
                                        onCheckboxChange(event, e)
                                      }
                                    />
                                  }
                                  label={e.display}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MultiChoiceQuestion;
