import { Feedback } from "../pages/health/encounters/cards/vitals/types/commonCDSTypes";

export const respiratoryRateRanges = {
  neonate: [30, 60],
  infant: [30, 53],
  toddler: [22, 37],
  preschooler: [20, 28],
  schoolAge: [18, 25],
  adolescent: [12, 20],
  adult: [12, 20],
} as const;

export function calculateRespiratoryRateFeedback(
  respiratoryRateValue: number,
  ageGroup: string,
): Feedback {
  if (Number.isNaN(respiratoryRateValue)) {
    return { message: "Invalid respiratory rate", type: "error" };
  }

  const range = respiratoryRateRanges[ageGroup];
  if (!range) {
    return {
      message: "No range defined for this age group",
      type: "warning",
    };
  }

  if (respiratoryRateValue >= range[0] && respiratoryRateValue <= range[1]) {
    return {
      message: "Respiratory rate is within the normal range",
      type: "success",
    };
  }

  if (respiratoryRateValue < range[0]) {
    return {
      message: "Respiratory rate is below the normal range",
      type: "warning",
    };
  }

  return {
    message: "Respiratory rate is above the normal range",
    type: "error",
  };
}
