import { FormControl, Grid, Typography } from "@mui/material";
import { get, set } from "lodash";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import useMedicalEncounter from "../../../../../../hooks/useMedicalEncounter";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { Vital } from "../../../../../../types/Vitals";
import { calculateRespiratoryRateFeedback } from "../../../../../../utils/respiratoryRateUtils";
import { AgeGroupField } from "../AgeGroupField";
import { SharedCDS } from "../constants";
import { Feedback } from "../types/commonCDSTypes";
import { getSimpleVitalValue, updateMetaData } from "../VitalsFormHelper";
import CdsArrowTooltip from "./cdsArrowTooltip";
import CdsFeedbackTextField from "./CdsFeedbackTextField";

const RespiratoryRateInput: FC<VitalsInputProps & { isModal?: boolean }> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
  isModal,
}) => {
  const practitionerReference = usePractitioner();
  const { currentPatient } = useMedicalEncounter();
  const [respiratoryRate, setRespiratoryRate] = useState<Vital>();
  const [ageGroup, setAgeGroup] = useState("adult");
  const [feedback, setFeedback] = useState<Feedback>();

  const handleRespiratoryRateChange = (respiratoryRateValue: number) => {
    setFeedback(
      calculateRespiratoryRateFeedback(respiratoryRateValue, ageGroup),
    );
  };

  const updateAgeGroup = (ageGroup: string) => {
    setAgeGroup(ageGroup);
    const newVital = { ...respiratoryRate };
    set(newVital, "referenceRange[0].age", SharedCDS.ageRanges[ageGroup]);
    updateMetaData(newVital, practitionerReference);
    onChange(newVital);
  };

  useEffect(() => {
    if (value) {
      setRespiratoryRate(value);
      setAgeGroup(SharedCDS.initializeAgeGroup(value, currentPatient));
      handleRespiratoryRateChange(parseInt(value?.valueQuantity?.value, 10));
      return;
    }
    const rr = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "9279-1",
      "Respiratory Rate",
      "/min",
      "",
      undefined,
    );
    const initialAgeGroup = SharedCDS.initializeAgeGroup(rr, currentPatient);
    set(rr, "referenceRange[0].age", SharedCDS.ageRanges[initialAgeGroup]);
    setAgeGroup(initialAgeGroup);
    setRespiratoryRate(rr);
  }, [encounter, patient, value, ageGroup]);

  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <CdsArrowTooltip
            type={feedback?.type}
            feedbackText={feedback?.message}
            openCDS={parseInt(value?.valueQuantity?.value, 10) >= 1}
          >
            <CdsFeedbackTextField
              feedbackType={
                getSimpleVitalValue(respiratoryRate)
                  ? feedback?.type
                  : undefined
              }
              value={getSimpleVitalValue(respiratoryRate) || ""}
              disabled={disabled}
              onChange={(e) => {
                const val = e.target.value ? Number(e.target.value) : null;
                const newVital = { ...respiratoryRate };
                set(newVital, "valueQuantity.value", val);
                updateMetaData(newVital, practitionerReference);
                onChange(newVital);
              }}
              id="vitals-respiratory-rate"
              label={size === "small" ? `RR` : `Respiratory Rate `}
              autoComplete="off"
              inputProps={{ inputProps: { type: "number" } }}
              InputProps={{
                endAdornment: (
                  <Typography pl={1} variant="body2">
                    {get(respiratoryRate, "valueQuantity.unit")}
                  </Typography>
                ),
              }}
              variant="outlined"
              size={size || "medium"}
            />
          </CdsArrowTooltip>
        </FormControl>
      </Grid>
      <Grid item xs={6} sx={{ pl: "5px" }}>
        {!isModal && (
          <AgeGroupField
            ageGroup={ageGroup}
            setAgeGroup={setAgeGroup}
            disabled={disabled}
            currentPatient={currentPatient}
            updateAgeGroup={updateAgeGroup}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default RespiratoryRateInput;
