import { TextField, TextFieldProps } from "@mui/material";
import { Palette, styled } from "@mui/material/styles";
import { useMemo } from "react";
import { FeedbackType } from "../types/commonCDSTypes";

type CdsFeedbackTextFieldProps = TextFieldProps & {
  feedbackType?: FeedbackType;
};

export const getBorderColor = (
  feedbackType: FeedbackType | undefined,
  palette: Palette,
  mode: "light" | "dark",
) => {
  switch (feedbackType) {
    case "error":
      return palette.error[mode];
    case "warning":
      return palette.warning[mode];
    case "info":
      return palette.info[mode];
    case "success":
      return palette.success[mode];
    default:
      return palette.action.disabled;
  }
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "feedbackType",
})<CdsFeedbackTextFieldProps>(({ theme, feedbackType }) => ({
  "& .MuiOutlinedInput-root": {
    "&.MuiInputBase-root fieldset": {
      borderWidth: feedbackType ? "2px" : "1px",
      borderColor: getBorderColor(
        feedbackType,
        theme.palette,
        theme.palette.mode,
      ),
    },
  },
}));

const CdsFeedbackTextField = ({
  feedbackType,
  ...props
}: CdsFeedbackTextFieldProps) => {
  const memoizedProps = useMemo(
    () => ({
      feedbackType,
      ...props,
    }),
    [feedbackType, props],
  );

  return <StyledTextField {...memoizedProps} />;
};

CdsFeedbackTextField.displayName = "CdsFeedbackTextField";

export default CdsFeedbackTextField;
