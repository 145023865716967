import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

const Question: FC<QuestionnaireItemProps> = ({ item }) => {
  return (
    <Grid>
      <Typography>{item.text}</Typography>
    </Grid>
  );
};

export default Question;
