import { Code } from "../../types/code";

export const initialTriageAssessment: Code[] = [
  { display: "Comfortable", code: "95468-5" },
  { display: "Ill-looking", code: "80218-8" },
  { display: "Cyanotic", code: "80304-6" },
  { display: "Sweaty/Diaphoretic", code: "75313-6" },
  { display: "Exhausted/Fatigued", code: "80262-6" },
  { display: "Agitated/Restless", code: "80309-5" },
];

export const initialAmbulationAssessment = [
  { display: "Independent", code: "LA12302-8" },
  { display: "Needed some help", code: "LA12303-6" },
  { display: "Dependent", code: "LA12304-4" },
];

export const initialBreathingAssessment = [
  { display: "Normal breathing", code: "LA30814-0" },
  { display: "Occasional laboured breathing", code: "LA33667-9" },
  { display: "Short period of hyperventilation", code: "LA33668-7" },
  { display: "Noisy laboured breathing", code: "LA33669-5" },
  { display: "Long period of hyperventilation", code: "LA33672-9" },
  { display: "Cheyne-Stokes", code: "LA25231-4" },
];
