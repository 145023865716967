export const formatDate = (date: Date) => {
  if (!date) {
    return undefined;
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}/${year}`;
};
