import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { get, set } from "lodash";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import useMedicalEncounter from "../../../../../../hooks/useMedicalEncounter";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { vitalsPulseMeasurement } from "../../../../../../slices/options/vitals";
import { Vital } from "../../../../../../types/Vitals";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";
import { calculateHeartRateFeedback } from "../../../../../../utils/heartRateUtils";
import { AgeGroupField } from "../AgeGroupField";
import HeartRateCDS, { SharedCDS } from "../constants";
import { Feedback } from "../types/commonCDSTypes";
import { getSimpleVitalValue, updateMetaData } from "../VitalsFormHelper";
import CdsArrowTooltip from "./cdsArrowTooltip";
import CdsFeedbackTextField from "./CdsFeedbackTextField";

const heartRateLocationDefault = createCodeableConcept(
  CodingSystems.BODY_POSITION,
  "LA24030-1",
  "Radial artery",
);

const HeartRateInput: FC<VitalsInputProps & { isModal?: boolean }> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
  isModal,
}) => {
  const practitionerReference = usePractitioner();
  const [heartRate, setHeartRate] = useState<Vital>();
  const [ageGroup, setAgeGroup] = useState("adult");
  const [activity, setActivity] = useState("awake");
  const [feedback, setFeedback] = useState<Feedback>();
  const heartRateLocationCode =
    heartRate?.bodySite?.coding?.[0]?.code ??
    heartRateLocationDefault.coding[0].code;
  const { currentPatient } = useMedicalEncounter();

  const handleHeartRateChange = (heartRateValue: number) => {
    setFeedback(calculateHeartRateFeedback(heartRateValue, ageGroup, activity));
  };

  useEffect(() => {
    if (value) {
      setHeartRate(value);
      setAgeGroup(SharedCDS.initializeAgeGroup(value, currentPatient));
      setActivity(value?.activity || "awake");
      handleHeartRateChange(parseInt(value?.valueQuantity?.value, 10));
      return;
    }
    const hr = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "8867-4",
      "Heart Rate",
      "/min",
      "",
      undefined,
      undefined,
      "awake",
    );
    const initialAgeGroup = SharedCDS.initializeAgeGroup(hr, currentPatient);
    set(hr, "referenceRange[0].age", SharedCDS.ageRanges[initialAgeGroup]);
    setAgeGroup(initialAgeGroup);
    setHeartRate(hr);
  }, [encounter, patient, value, activity, ageGroup]);

  const updateAgeGroup = (ageGroup: string) => {
    const newVital = { ...heartRate };
    set(newVital, "referenceRange[0].age", SharedCDS.ageRanges[ageGroup]);
    updateMetaData(newVital, practitionerReference);
    onChange(newVital);
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <CdsArrowTooltip
              type={feedback?.type}
              feedbackText={feedback?.message}
              openCDS={parseInt(value?.valueQuantity?.value, 10) >= 1}
            >
              <CdsFeedbackTextField
                feedbackType={
                  getSimpleVitalValue(heartRate) ? feedback?.type : undefined
                }
                id="vitals-bpm"
                key={"vital-weight-input"}
                value={getSimpleVitalValue(heartRate) || ""}
                disabled={disabled}
                autoComplete="off"
                onChange={(e) => {
                  const val: number | null = e.target.value
                    ? Number(e.target.value)
                    : null;
                  const newHeartRate = { ...heartRate };
                  set(newHeartRate, "valueQuantity.value", val);
                  updateMetaData(newHeartRate, practitionerReference);
                  onChange(newHeartRate);
                }}
                label={size === "small" ? `HR` : `Heart Rate `}
                inputProps={{ inputProps: { type: "number" } }}
                InputProps={{
                  endAdornment: (
                    <Typography pl={1} variant="body2">
                      {get(heartRate, "valueQuantity.unit")}
                    </Typography>
                  ),
                }}
                variant="outlined"
                size={size || "medium"}
              />
            </CdsArrowTooltip>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ pl: "5px" }}>
          <FormControl fullWidth>
            <TextField
              select
              id="vitals-patient-heart-rate-measurement"
              disabled={disabled}
              defaultValue="LA24030-1"
              value={heartRateLocationCode}
              onChange={(e) => {
                const code = e.target.value;
                const opt = vitalsPulseMeasurement.find((o) => o.code === code);
                const hrm = { ...heartRate };

                if (opt) {
                  const site = createCodeableConcept(
                    CodingSystems.APPROACH_SITE_CODES,
                    opt.code,
                    opt.display,
                  );
                  hrm.bodySite = site;
                } else {
                  hrm.bodySite = null;
                }
                setHeartRate(hrm);
                updateMetaData(hrm, practitionerReference);
                onChange(hrm);
              }}
              label="Pulse Site"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            >
              {vitalsPulseMeasurement.map((c) => (
                <MenuItem value={c.code} key={c.code}>
                  {c.display}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container xs={12} mt={3}>
        {!isModal && (
          <Grid item xs={6}>
            <AgeGroupField
              ageGroup={ageGroup}
              setAgeGroup={setAgeGroup}
              disabled={disabled}
              currentPatient={currentPatient}
              updateAgeGroup={updateAgeGroup}
            />
          </Grid>
        )}
        <Grid item xs={6} sx={{ pl: "5px" }}>
          <FormControl fullWidth>
            <TextField
              select
              id="activity"
              value={activity}
              disabled={disabled}
              onChange={(e) => {
                setActivity(e.target.value);
                handleHeartRateChange(
                  parseInt(value?.valueQuantity?.value, 10),
                );
                const newHeartRate = { ...heartRate, activity: e.target.value };
                updateMetaData(newHeartRate, practitionerReference);
                onChange(newHeartRate);
              }}
              label={"Activity"}
              variant="outlined"
              size={size || "medium"}
            >
              {HeartRateCDS.activities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default HeartRateInput;
