import { camelCase } from "lodash";
import { bloodGlucoseTypes } from "../slices/options/anatomy";

export type BloodGlucoseTestType =
  | "a1c"
  | "fasting blood sugar"
  | "glucose tolerance"
  | "random blood sugar";
export type FeedbackType = "error" | "warning" | "success";

export interface Feedback {
  message: string;
  type: FeedbackType;
}

export const A1C_DISPLAY = bloodGlucoseTypes[0].display.toLowerCase();

export const bloodGlucoseRanges = {
  a1C: {
    diabetes: { min: 6.5, unit: "%" },
    prediabetes: { min: 5.7, max: 6.4, unit: "%" },
    normal: { max: 5.6, unit: "%" },
  },
  fastingBloodSugar: {
    diabetes: { min: 126, min_mmol: 7.0, unit: "mg/dL / mmol/L" },
    prediabetes: {
      min: 100,
      max: 125,
      min_mmol: 5.6,
      max_mmol: 6.9,
      unit: "mg/dL / mmol/L",
    },
    normal: { max: 99, max_mmol: 5.5, unit: "mg/dL / mmol/L" },
    hypoglycemia: { max: 70, max_mmol: 3.9, unit: "mg/dL / mmol/L" },
  },
  glucoseTolerance: {
    diabetes: { min: 200, min_mmol: 11.1, unit: "mg/dL / mmol/L" },
    prediabetes: {
      min: 140,
      max: 199,
      min_mmol: 7.8,
      max_mmol: 11.0,
      unit: "mg/dL / mmol/L",
    },
    normal: { max: 139, max_mmol: 7.7, unit: "mg/dL / mmol/L" },
    hypoglycemia: { max: 70, max_mmol: 3.9, unit: "mg/dL / mmol/L" },
  },
  randomBloodSugar: {
    diabetes: { min: 200, min_mmol: 11.1, unit: "mg/dL / mmol/L" },
    normal: { max: 199, max_mmol: 11.0, unit: "mg/dL / mmol/L" },
    hypoglycemia: { max: 70, max_mmol: 3.9, unit: "mg/dL / mmol/L" },
  },
};

export function calculateBloodGlucoseFeedback(
  glucoseValue: number,
  testType: string,
  unit: string,
): Feedback {
  if (Number.isNaN(glucoseValue)) {
    return { message: "Invalid glucose level", type: "error" };
  }

  const ranges = bloodGlucoseRanges[camelCase(testType)];
  const isMMOL = unit === "mmol/L";

  const rangeChecks = [
    {
      condition:
        ranges.hypoglycemia &&
        glucoseValue <=
          (isMMOL ? ranges.hypoglycemia.max_mmol : ranges.hypoglycemia.max),
      message: "Hypoglycemia range",
      type: "warning" as const,
    },
    {
      condition:
        ranges.diabetes &&
        glucoseValue >=
          (isMMOL ? ranges.diabetes.min_mmol : ranges.diabetes.min),
      message: "Diabetes range",
      type: "error" as const,
    },
    {
      condition:
        ranges.prediabetes &&
        glucoseValue >=
          (isMMOL ? ranges.prediabetes.min_mmol : ranges.prediabetes.min) &&
        glucoseValue <=
          (isMMOL ? ranges.prediabetes.max_mmol : ranges.prediabetes.max),
      message: "Prediabetes range",
      type: "warning" as const,
    },
    {
      condition:
        ranges.normal &&
        glucoseValue <= (isMMOL ? ranges.normal.max_mmol : ranges.normal.max),
      message: "Normal range",
      type: "success" as const,
    },
  ];

  const matchedRange = rangeChecks.find((check) => check.condition);
  return matchedRange || { message: "Invalid range", type: "error" };
}
