import HeartRateCDS from "../pages/health/encounters/cards/vitals/constants";
import { Feedback } from "../pages/health/encounters/cards/vitals/types/commonCDSTypes";

export type AgeGroup = "infant" | "child" | "adult";
export type Activity = "awake" | "sleeping" | "exercising";

export interface ActivityOption {
  value: Activity;
  label: string;
}

// export const activities: ActivityOption[] = [
//   { value: "awake", label: "Awake" },
//   { value: "sleeping", label: "Sleeping" },
//   { value: "exercising", label: "Exercising" },
// ];

export function calculateHeartRateFeedback(
  heartRateValue: number,
  ageGroup: string,
  activity: string,
): Feedback {
  if (Number.isNaN(heartRateValue)) {
    return { message: "Invalid Heart Rate", type: "error" };
  }

  const range = HeartRateCDS.ranges[ageGroup]?.[activity];

  if (!range) {
    return {
      message: "No range defined for this activity",
      type: "warning",
    };
  }

  const [min, max] = range;

  if (heartRateValue >= min && heartRateValue <= max) {
    return { message: "Normal heart rate", type: "success" };
  } else if (heartRateValue < min) {
    return {
      message: "Heart rate is below the normal range",
      type: "warning",
    };
  } else {
    return {
      message: "Heart rate is above the normal range",
      type: "error",
    };
  }
}
