import { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";
import { initialState } from "../../../../../slices/options/options";
import { remove } from "lodash";

const OpenChoiceQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  required,
  disabled,
  initialValue,
  answerValueSet,
}) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [valueMap, setValueMap] = useState({});

  useEffect(() => {
    if (value && value.length > 0) {
      const vmap = {};
      value.forEach((v) => {
        vmap[v.value.code] = true;
      });
      setValueMap(vmap);
    } else {
      setValueMap({});
    }
  }, [value]);

  useEffect(() => {
    if (answerValueSet && initialState[answerValueSet]) {
      setAvailableOptions(initialState[answerValueSet]);
    }
  }, [answerValueSet]);

  return (
    <Grid>
      <FormControl fullWidth>
        <FormLabel required={required}>{item.text}</FormLabel>
        <Grid container>
          {availableOptions.map((option, i) => {
            return (
              <Grid item md={4} key={option.code}>
                <FormControlLabel
                  label={option.display}
                  control={
                    <Checkbox
                      value={valueMap[option.code] || false}
                      checked={valueMap[option.code] || false}
                      name={option.code}
                      disabled={disabled}
                      onChange={() => {
                        const v = value ? value : [];
                        if (!valueMap[option.code]) {
                          onChange(item, [...v, option]);
                        } else {
                          remove(
                            value,
                            (v: any) => v.value.code === option.code,
                          );
                          onChange(item, value);
                        }
                      }}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </FormControl>
    </Grid>
  );
};

export default OpenChoiceQuestion;
