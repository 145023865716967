import { FC, useEffect, useState } from "react";
import { QuestionnaireItemProps } from ".";
import { useSelector } from "../../../../../store";
import moment from "moment";
import {
  FormControl,
  FormLabel,
  TextField,
  Box,
  Grid,
  Typography,
} from "@mui/material";

// allows numbers, arithmetic operators, spaces and hashtag signs.
// consecutive hashtags are not allowed. could improve by disallowing
// stray hashtag symbols. can be enforced at formula insertion level.
const allowed = /^(?!.*##)[0-9\s+-/*()#.~a-zA-Z]+$/;

const Calculated: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  disabled,
  answers,
  formula,
  answerValueSet,
}) => {
  const options = useSelector((state) => state.options[answerValueSet]) ?? [];
  const [calculatedValue, setCalculatedValue] = useState<number>(0);

  const replaceLinkIds = (value: string, answers: any) => {
    if (value.includes("~")) {
      const linkId = value.split("~")[1].replace("#", "").trim();
      console.log(linkId);
      return answers[linkId] && answers[linkId].value
        ? answers[linkId].value
        : "0";
    }
    let temp = value;
    const linkIds =
      value.match(/#\d+\.\d+/g)?.map((e) => e.replace("#", "")) ?? [];
    for (let i = 0; i < linkIds.length; i++) {
      temp = temp.replace(
        `#${linkIds[i]}`,
        answers[linkIds[i]] && answers[linkIds[i]].value
          ? answers[linkIds[i]].value ?? "0"
          : "0",
      );
    }
    return temp;
  };
  const calculateGestationAge = (lmpDate) => {
    const gestationalAge = moment.duration(moment().diff(lmpDate));
    return `${Math.floor(gestationalAge.asWeeks())} Weeks and ${Math.floor(
      gestationalAge.asDays() % 7,
    )} Days`;
  };
  const calculateEDD = (lmpDate) => {
    const edd = moment(lmpDate)
      .add(7, "days")
      .subtract(3, "months")
      .add(1, "years")
      .format("DD/MM/YYYY");
    return edd;
  };

  const validateBrackets = (raw: string[]) => {
    if (!raw.includes("(") && !raw.includes(")")) {
      return true;
    }
    const brackets = raw.filter((e) => ["(", ")"].includes(e));

    let bracketsCount = 0;

    for (let i = 0; i < brackets.length; i++) {
      if (brackets[i] === "(") {
        bracketsCount++;
      } else if (brackets[i] === ")") {
        if (bracketsCount === 0) {
          return false;
        }

        bracketsCount--;
      }
    }

    return bracketsCount === 0;
  };

  const validate = (value: string) =>
    allowed.test(value) &&
    (value.includes("~") || validateBrackets(value.split("")));

  const calculate = (formula: string, answers: any) => {
    if (!validate(formula)) throw new Error("Invalid formula");
    if (formula.includes("~")) {
      const functionName = formula.split("~")[0].trim();
      const linkIdAnswer = replaceLinkIds(formula, answers);
      console.log(linkIdAnswer);
      switch (functionName) {
        case "GA":
          return calculateGestationAge(linkIdAnswer);
        case "EDD":
          return calculateEDD(linkIdAnswer);
        default:
          throw new Error(`Invalid function name: ${functionName}`);
      }
    }
    const calculationString = replaceLinkIds(formula, answers);
    // eslint-disable-next-line no-eval
    return eval(calculationString);
  };

  useEffect(() => {
    if (
      typeof calculatedValue === "number" ||
      typeof calculatedValue === "string"
    ) {
      if (
        !answers[item.linkId] ||
        !answers[item.linkId].value ||
        answers[item.linkId].value !== calculatedValue
      ) {
        onChange(item, calculatedValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedValue]);

  useEffect(() => {
    try {
      const value = calculate(formula, answers);
      setCalculatedValue(value);
    } catch (error) {
      console.log(error);
      setCalculatedValue(0);
      console.log(formula);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return (
    <Grid>
      {formula && formula?.includes("~") ? (
        <Box>
          <FormLabel>{item.text}</FormLabel>
          <Typography variant="h6">{calculatedValue}</Typography>
        </Box>
      ) : (
        <FormControl>
          <FormLabel>{item.text}</FormLabel>
          <TextField
            fullWidth
            value={calculatedValue}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      )}
    </Grid>
  );
};

export default Calculated;
