export class FHIRURL {
  /** ID */
  public id: string;
  /** e.g. Questionnaire/12345?version=1 */
  public url: string;
  /** e.g. Questionnaire/12345 */
  public pathname: string;
  /** Search params */
  public searchParams: URLSearchParams;

  public constructor(value: string, query?: { [key: string]: any }) {
    const _url = new URL(value, "http://localhost:0/");

    if (query) {
      Object.keys(query).forEach((key) => {
        if (query[key]) {
          _url.searchParams.set(key, query[key]);
        }
      });
    }

    this.searchParams = _url.searchParams;
    this.pathname = _url.pathname.substring(1);
    this.url = `${_url.pathname.substring(1)}${_url.search}`;
    this.id = this.pathname.split("/")[1];
  }
}
