import { QuestionnaireItemProps, QuestionnaireItemTypes } from ".";

type GenderOptionItem = {
  male: {
    linkId: string;
    text: string;
    type: string;
  };
  female: {
    linkId: string;
    text: string;
    type: string;
  };
};

export default function GenderOption({
  item,
  onChange,
  value,
  disabled,
  answers,
  formula,
  sex,
}: QuestionnaireItemProps) {
  const genderOptionItems: GenderOptionItem = {
    male: item.item[0],
    female: item.item[1],
  };

  if (!sex || (sex && !["male", "female"].includes(sex.toLowerCase()))) {
    return <p>Patient sex not specified</p>;
  }

  const patientGenderItem = genderOptionItems[sex.toLowerCase()];

  const QuestionnaireItem = QuestionnaireItemTypes[patientGenderItem.type];

  return (
    <QuestionnaireItem
      item={patientGenderItem}
      onChange={onChange}
      value={value}
      disabled={disabled}
      answers={answers}
      formula={formula}
      sex={sex}
    />
  );
}
