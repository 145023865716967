import { Feedback } from "../pages/health/encounters/cards/vitals/types/commonCDSTypes";

export const spo2Ranges = {
  normal: [95, 100],
  concerning: [91, 94],
  lowBrainFunction: [80, 85],
  cyanosis: [0, 67],
  low: [0, 90],
} as const;

export function calculateOxygenSaturationFeedback(spO2Value: number): Feedback {
  if (Number.isNaN(spO2Value)) {
    return { message: "Invalid Oxygen Saturation", type: "error" };
  }

  if (spO2Value >= spo2Ranges.normal[0] && spO2Value <= spo2Ranges.normal[1]) {
    return { message: "Normal blood oxygen level", type: "success" };
  }

  if (
    spO2Value >= spo2Ranges.concerning[0] &&
    spO2Value <= spo2Ranges.concerning[1]
  ) {
    return {
      message: "Concerning blood oxygen level",
      type: "warning",
    };
  }

  if (
    spO2Value >= spo2Ranges.lowBrainFunction[0] &&
    spO2Value <= spo2Ranges.lowBrainFunction[1]
  ) {
    return {
      message: "Low oxygen saturation affects brain function",
      type: "warning",
    };
  }

  if (spO2Value <= spo2Ranges.cyanosis[1]) {
    return {
      message: "Cyanosis (critical low oxygen level)",
      type: "error",
    };
  }

  return { message: "Low blood oxygen level", type: "error" };
}
