import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

const DateQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  initialValue,
  disabled,
  required,
}) => {
  return (
    <Grid>
      <FormControl fullWidth>
        <FormLabel required={required}>{item.text}</FormLabel>
        <DatePicker
          value={value?.value || initialValue || null}
          onChange={(value) => onChange(item, value)}
          renderInput={(props) => (
            <TextField
              {...props}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
        />
      </FormControl>
    </Grid>
  );
};

export default DateQuestion;
