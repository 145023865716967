import { FormControl, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { createDefaultVital } from ".";
import { BloodPressureInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import useMedicalEncounter from "../../../../../../hooks/useMedicalEncounter";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";
import { inputHintHelper } from "../../../../../../utils/inputHintHelpers";
import {
  createObservationComponent,
  getBloodPressureDisplay,
  updateMetaData,
} from "../VitalsFormHelper";
import CdsFeedbackTextField from "./CdsFeedbackTextField";
import CdsArrowTooltip from "./cdsArrowTooltip";

const BloodPressureInput: FC<BloodPressureInputProps> = ({
  disabled,
  value,
  onChange,
  size,
  type,
  onBpInput,
}) => {
  const practitionerReference = usePractitioner();

  const { encounterReference, patientReference } = useMedicalEncounter();

  const validateInput = (text) => !text || /^[0-9/]*$/.test(text);
  const validateBp = (text) => !text || /\d+\/\d+/.test(text);

  const inputValue =
    type === "form" ? value || "" : getBloodPressureDisplay(value) || "";

  const onChangeInput = (text) => {
    if (!validateInput) return;

    if (type === "form") {
      onBpInput(true);
      onChange(text);
      return;
    }

    const tempBase = {
      ...value,
      updated: true,
    };

    try {
      if (!text.includes("/")) {
        tempBase.component = null;
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };

        return;
      }

      const measurements = text.split("/");
      if (
        measurements.length === 2 &&
        measurements[0].length > 0 &&
        measurements[1].length > 0
      ) {
        const sys = createObservationComponent(
          createCodeableConcept(
            CodingSystems.OBSERVATION_CODE,
            "8480-6",
            "Systolic blood pressure",
          ),
          Number(measurements[0]),
          "mmHg",
          "mmHg",
        );
        const dia = createObservationComponent(
          createCodeableConcept(
            CodingSystems.OBSERVATION_CODE,
            "8462-4",
            "Diastolic blood pressure",
          ),
          Number(measurements[1]),
          "mmHg",
          "mmHg",
        );

        tempBase.component = [sys, dia];
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };

        return;
      } else {
        tempBase.component = null;
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      updateMetaData(tempBase, practitionerReference);

      const defaultVital = createDefaultVital(
        encounterReference,
        patientReference,
        CodingSystems.OBSERVATION_CODE,
        "96607-7",
        "Average Blood Pressure",
        "mmHg",
      );

      const val = {
        ...defaultVital,
        ...tempBase,
        valueQuantity: {
          ...defaultVital.valueQuantity,
          ...tempBase?.valueQuantity,
        },
      };

      onChange(val);
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <CdsArrowTooltip
          type={inputHintHelper.bp(inputValue)?.type}
          feedbackText={inputHintHelper.bp(inputValue)?.message}
          openCDS={
            validateBp(inputValue) ? parseInt(inputValue, 10) >= 1 : false
          }
        >
          <CdsFeedbackTextField
            id="vitals-blood-pressure"
            value={inputValue}
            feedbackType={inputHintHelper.bp(inputValue)?.type}
            disabled={disabled}
            autoComplete="off"
            error={!validateBp(inputValue)}
            inputProps={{ maxLength: 7 }}
            variant="outlined"
            size={size ?? "medium"}
            type={type ?? "modal"}
            label="Sys/Dia"
            InputProps={{
              endAdornment: (
                <Typography sx={{ fontSize: "14px" }}>mmHg</Typography>
              ),
            }}
            onChange={(e) => onChangeInput(e.target.value)}
            helperText={
              validateBp(inputValue) ? undefined : "Format: systolic/diastolic"
            }
          />
        </CdsArrowTooltip>
      </FormControl>
    </Grid>
  );
};

export default BloodPressureInput;
