import { FormControl, Grid, Typography } from "@mui/material";
import { get, set } from "lodash";
import { FC, useState } from "react";
import { createDefaultVital } from ".";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import { useSelector } from "../../../../../../store";
import { Vital } from "../../../../../../types/Vitals";
import { calculateOxygenSaturationFeedback } from "../../../../../../utils/oxygenSaturationUtils";
import { testRegExp } from "../../../../../../utils/regex";
import { Feedback } from "../types/commonCDSTypes";
import { getSimpleVitalValue, updateMetaData } from "../VitalsFormHelper";
import CdsArrowTooltip from "./cdsArrowTooltip";
import CdsFeedbackTextField from "./CdsFeedbackTextField";

const OxygenSaturationInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = useSelector(
    (state) => state.user.practitionerReference,
  );

  const [oxygenSaturation, setOxygenSaturation] = useState<Vital>(
    value ||
      createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "2708-6",
        "Oxygen Saturation",
        "%",
      ),
  );

  const [feedback, setFeedback] = useState<Feedback>(
    value?.valueQuantity?.value
      ? calculateOxygenSaturationFeedback(
          parseInt(value.valueQuantity.value, 10),
        )
      : undefined,
  );

  const handleChange = (event) => {
    const eventValue = event.target.value;
    const valid = testRegExp(
      "non-negative integer or empty string",
      eventValue,
    );
    if (!valid) return;
    if (eventValue > 100 || eventValue < 0) return;

    const newOxygenSat = { ...oxygenSaturation };
    set(newOxygenSat, "valueQuantity.value", eventValue);

    // Update feedback
    setFeedback(calculateOxygenSaturationFeedback(parseInt(eventValue, 10)));

    // Update state and parent
    setOxygenSaturation(newOxygenSat);
    updateMetaData(newOxygenSat, practitionerReference);
    onChange(newOxygenSat);
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <CdsArrowTooltip
          type={feedback?.type}
          feedbackText={feedback?.message}
          openCDS={parseInt(oxygenSaturation?.valueQuantity?.value, 10) >= 1}
        >
          <CdsFeedbackTextField
            feedbackType={
              getSimpleVitalValue(oxygenSaturation) ? feedback?.type : undefined
            }
            value={getSimpleVitalValue(oxygenSaturation) || ""}
            disabled={disabled}
            onChange={handleChange}
            id="vitals-oxygen-sat"
            label={size === "small" ? `SpO2` : `O2 Saturation`}
            inputMode="numeric"
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <Typography pl={1} variant="body2">
                  {get(oxygenSaturation, "valueQuantity.unit")}
                </Typography>
              ),
            }}
            variant="outlined"
            size={size || "medium"}
            type="text"
          />
        </CdsArrowTooltip>
      </FormControl>
    </Grid>
  );
};

export default OxygenSaturationInput;
