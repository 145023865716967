import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC } from "react";
import { QuestionnaireItemProps } from ".";

const BooleanQuestion: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  value,
  disabled,
  initialValue,
}) => {
  return (
    <Grid>
      <FormControl>
        <FormLabel>{item.text}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={value?.value || initialValue}
          onChange={(e) => onChange(item, e.target.value)}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Yes"
            disabled={disabled}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="No"
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default BooleanQuestion;
