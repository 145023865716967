export interface Coding {
  system?: string;
  version?: string;
  code: string;
  display?: string;
  userSelected?: boolean;
}

export const isTypeCoding = (value): value is Coding => {
  return (value as Coding)?.code !== undefined;
};
export interface CodeableConcept {
  coding?: Coding[];
  text?: string;
}

export const isTypeCodeableConcept = (value): value is CodeableConcept => {
  return (value as CodeableConcept)?.coding !== undefined;
};

export const emptyCodeableConcept = (): CodeableConcept => {
  return {
    coding: [],
    text: "",
  };
};
