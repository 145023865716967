import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import NumberInput from "../../../../../../components/NumberInput";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { useMedicalConfig } from "../../../../../../slices/configuration";
import { temperatureMethod } from "../../../../../../slices/options/anatomy";
import { temperatureUnit } from "../../../../../../slices/options/vitals";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";
import { calculateTemperatureFeedback } from "../../../../../../utils/temperatureUtils";
import { Feedback } from "../types/commonCDSTypes";
import { updateMetaData } from "../VitalsFormHelper";
import CdsArrowTooltip from "./cdsArrowTooltip";
import { getBorderColor } from "./CdsFeedbackTextField";

const defaultMethod = createCodeableConcept(
  CodingSystems.OBSERVATION_METHOD,
  "LA10494-1",
  "axillary",
);
const TemperatureInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const theme = useTheme();
  const practitionerReference = usePractitioner();
  const [temperature, setTemperature] = useState<any>();
  const [method, setMethod] = useState("axillary");
  const [unit, setUnit] = useState("celcius");
  const [feedback, setFeedback] = useState<Feedback>();
  const medicalConfig = useMedicalConfig();
  const currentUnit =
    temperatureUnit?.find((e) => e.code === value?.valueQuantity?.unit)
      ?.display ??
    temperatureUnit?.find(
      (e) =>
        e.code === value?.valueQuantity?.unit ||
        e.code === medicalConfig?.vitalMeasurement?.temperature,
    )?.display ??
    temperatureUnit?.[0]?.display;

  const handleTemperatureChange = (tempValue: number) => {
    setFeedback(calculateTemperatureFeedback(tempValue, method, unit));
  };

  useEffect(() => {
    if (value) {
      setTemperature(value);
      setMethod(value?.method?.coding?.[0]?.display ?? "axillary");
      setUnit(value?.valueQuantity?.unit ?? "celcius");
      handleTemperatureChange(parseInt(value?.valueQuantity?.value));
    } else {
      const t = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "8310-5",
        "Body Temperature",
        medicalConfig?.vitalMeasurement?.temperature ?? "celcius",
        "",
        defaultMethod,
      );
      setTemperature(t);
    }
  }, [encounter, medicalConfig, patient, value, method]);
  return (
    <FormControl fullWidth>
      <Grid container xs={12}>
        <CdsArrowTooltip
          type={feedback?.type}
          feedbackText={feedback?.message}
          openCDS={parseInt(value?.valueQuantity?.value) >= 1}
        >
          <Grid item xs={6} sx={{ pr: "5px" }}>
            <NumberInput
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.MuiInputBase-root fieldset": {
                    borderWidth: feedback?.type ? "2px" : "1px",
                    borderColor: getBorderColor(
                      feedback?.type,
                      theme.palette,
                      theme.palette.mode,
                    ),
                  },
                },
              }}
              value={value?.valueQuantity?.value ?? ""}
              label="Temp"
              size="small"
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <SelectButton
                    label={currentUnit}
                    variant="text"
                    options={temperatureUnit}
                    getOptionLabel={(option) => option?.display ?? ""}
                    size={size}
                    disabled={disabled}
                    onSelect={(tempUnit) => {
                      setUnit(tempUnit.code);
                      const newVital = temperature.valueQuantity
                        ? { ...temperature.valueQuantity, unit: tempUnit.code }
                        : {};

                      const copy = { ...temperature, valueQuantity: newVital };
                      updateMetaData(copy, practitionerReference);
                      onChange(copy);
                    }}
                  />
                ),
              }}
              onChange={(e) => {
                const newVital = temperature.valueQuantity
                  ? { ...temperature.valueQuantity, value: e.target.value }
                  : {};

                const tempCopy = { ...temperature };

                updateMetaData(tempCopy, practitionerReference);
                onChange({ ...tempCopy, valueQuantity: newVital });
              }}
            />
          </Grid>
        </CdsArrowTooltip>
        <Grid item xs={6} sx={{ pl: "5px" }}>
          <TextField
            fullWidth
            select
            id="temp-method"
            value={method}
            onChange={(e) => {
              setMethod(e.target.value);
              const newVital = {
                ...temperature,
                method: createCodeableConcept(
                  CodingSystems.OBSERVATION_METHOD,
                  temperatureMethod.find(
                    (c) => c.display.toLowerCase() === e.target.value,
                  )?.code,
                  e.target.value,
                ),
              };
              updateMetaData(newVital, practitionerReference);
              onChange(newVital);
            }}
            label="Temp Site"
            variant="outlined"
            size="small"
          >
            {temperatureMethod.map((c) => (
              <MenuItem value={c.display.toLowerCase()} key={c.code}>
                {c.display}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default TemperatureInput;
