import { useFlags } from "launchdarkly-react-client-sdk";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Divider, Drawer, Theme, useMediaQuery } from "@mui/material";
import { LocalHospital } from "@mui/icons-material";
import MedicationIcon from "@mui/icons-material/Medication";
import CalendarIcon from "../../icons/Calendar";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import UsersIcon from "../../icons/Users";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import PencilAlt from "../../icons/PencilAlt";
import Bell from "../../icons/Bell";
import { useSelector } from "../../store";
import { shouldRenderMenuItem } from "../../utils/shouldRenderMenuItem";
import ChartPie from "../../icons/ChartPie";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: "Overview",
    items: [
      {
        title: "Executive Dashboard",
        path: "/home/summary",
        ldFlag: "allowExecutiveDashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
        allowedRoles: ["Administrator", "Executive"],
      },
      {
        title: "Summary",
        path: "/home",
        ldFlag: "homeDashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: "Appointments",
        path: "/appointments",
        ldFlag: "appointmentsFeature",
        icon: <CalendarIcon fontSize="small" />,
      },
      {
        title: "Communications",
        path: "/communications/broadcast",
        ldFlag: "broadcastFeature",
        icon: <Bell fontSize="small" />,
        tier: ["TRIAL", "PREMIUM"],
      },
    ],
  },
  {
    title: "Health Information Management",
    ldOrgFlag: "medicalRecordEnabled",
    allowedRoles: ["Practitioner", "Medical Assistant", "Nurse Practitioner"],
    items: [
      {
        title: "Summary",
        path: "/health/summary",
        ldFlag: "healthInformationSummaryFeature",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: "Triage Board",
        path: "/health/triage",
        ldFlag: "allowTriageDashboard",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <ChartPie fontSize="small" />,
      },
      {
        title: "Medical Encounters",
        path: "/health/encounters",
        ldFlag: "medicalEncountersFeature",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Care Plans",
        path: "/health/plans",
        ldFlag: "carePlanFeature",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <PencilAlt fontSize="small" />,
      },
      {
        title: "Prescriptions",
        path: "/health/prescriptions",
        ldFlag: "prescriptionFeature",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <MedicationIcon fontSize="small" />,
      },
      {
        title: "Medication Administration",
        path: "/health/medication-administrations",
        ldFlag: "medicationAdministrationFeature",
        ldOrgFlag: "medicalRecordEnabled",
        icon: <MedicationIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Patient Directory",
    items: [
      {
        title: "Patients",
        path: "/patients",
        icon: <UsersIcon fontSize="small" />,
      },
    ],
  },

  {
    title: "Administration",
    items: [
      {
        title: "Staff Directory",
        path: "/staff",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Facility",
        path: "/facility",
        ldFlag: "facilityManagementFeature",
        icon: <LocalHospital fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const flags = useFlags();
  const location = useLocation();
  const currentOrg = useSelector((state) => state.user.currentOrganization);
  const userRoles = useSelector((state) => state.user.roles);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [activeSections, setActiveSections] = useState(sections);

  useEffect(() => {
    if (flags && currentOrg && userRoles) {
      const active = sections.filter(
        (section) =>
          shouldRenderMenuItem(
            section,
            flags,
            currentOrg.adminSettings,
            currentOrg.id,
            userRoles,
          ) &&
          section.items &&
          section.items.length > 0,
      );
      setActiveSections(active);
    }
  }, [flags, currentOrg, userRoles]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <nav aria-label="Dashboard Sidebar Navigation">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {flags && userRoles && currentOrg && (
          <Scrollbar options={{ suppressScrollX: true }}>
            <Box
              sx={{
                display: {
                  lg: "none",
                  xs: "flex",
                },
                justifyContent: "center",
                p: 2,
              }}
            >
              <RouterLink to="/">
                <Logo
                  sx={{
                    height: 40,
                    width: 40,
                  }}
                />
              </RouterLink>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              {activeSections.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))}
            </Box>
          </Scrollbar>
        )}
      </Box>
    </nav>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
