/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";
import { TCopilot } from "../types/copilot";
import { Reference } from "../types/reference";

export type TMedicalEncounter = {
  isCopiloting: boolean;
  currentName: any;
  currentPatient: any;
  currentEncounter: any;
  currentItem: any;
  encounterReference: Reference;
  patientReference: Reference;
  currentDiagnosis: any;
  refreshHistory;
  refreshProcedure;
  refetchCurrentDiagnosis;
  copilot?: TCopilot;
  setCurrentPatient: (e: any) => void;
  setCurrentItem: (e: any) => void;
  setCurrentEncounter: (e: any) => void;
  resetEncounter: () => void;
  setCurrentEncounterRefrence: (e: any) => void;
  setCurrentPatientReference: (e: any) => void;
  setEncounterDiagnosis: (e: unknown) => void;
  setCurrentName: (e: any) => void;
  refreshPatientHistory: () => void;
  refreshProceduresList: () => void;
  refreshDiagnosisList: () => void;
  setIsCopiloting: (e) => void;
  setCopilot: (e) => void;
};

const defaultApi: TMedicalEncounter = {
  isCopiloting: false,
  currentName: undefined,
  currentPatient: undefined,
  currentEncounter: undefined,
  currentItem: undefined,
  encounterReference: undefined,
  patientReference: undefined,
  currentDiagnosis: undefined,
  refreshHistory: undefined,
  refreshProcedure: undefined,
  refetchCurrentDiagnosis: undefined,
  copilot: undefined,
  setCurrentPatient: (e): void => null,
  setCurrentItem: (e): void => null,
  setCurrentEncounter: (e): void => null,
  resetEncounter: (): void => null,
  setCurrentEncounterRefrence: (e): void => null,
  setCurrentPatientReference: (e): void => null,
  setEncounterDiagnosis: (e): void => null,
  setCurrentName: (e): void => null,
  refreshPatientHistory: (): void => null,
  refreshProceduresList: (): void => null,
  refreshDiagnosisList: (): void => null,
  setIsCopiloting: (e): void => null,
  setCopilot: (e): void => null,
};

export const MedicalEncounterContext = createContext(defaultApi);

export function MedicalEncounterProvider({ children }: any) {
  const [currentPatient, setPatient] = useState<any>(undefined);
  const [currentEncounter, setEncounter] = useState<any>(undefined);
  const [currentItem, setItem] = useState<any>(undefined);
  const [refreshHistory, setRefreshHistory] = useState<boolean>(false);
  const [refreshProcedure, setRefreshProcedure] = useState<boolean>(false);
  const [encounterReference, setEncounterReference] = useState<any>(undefined);
  const [patientReference, setPatientReference] = useState<any>(undefined);
  const [currentDiagnosis, setCurrentDiagnosis] = useState<any>(undefined);
  const [refetchCurrentDiagnosis, setRefetchCurrentDiagnosis] =
    useState<boolean>(false);
  const [currentName, setCurrentFirstName] = useState<any>(undefined);

  const [isCopiloting, setIsCopiloting] = useState<boolean>(false);
  const [copilot, setCopilot] = useState<TCopilot>();

  const setCurrentName = (name) => setCurrentFirstName(name);
  const setCurrentPatient = (patient) => setPatient(patient);
  const setCurrentEncounter = (encounter) => setEncounter(encounter);
  const setCurrentItem = (item) => setItem(item);
  const setCurrentPatientReference = (patient) => setPatientReference(patient);
  const setCurrentEncounterRefrence = (encounter) =>
    setEncounterReference(encounter);
  const setEncounterDiagnosis = (diagnosisList) =>
    setCurrentDiagnosis(diagnosisList);

  const refreshPatientHistory = () => {
    setRefreshHistory(true);
    setRefreshHistory(false);
  };

  const refreshProceduresList = () => {
    setRefreshProcedure(true);
    setRefreshProcedure(false);
  };

  const refreshDiagnosisList = () => {
    setRefetchCurrentDiagnosis(true);
    setRefetchCurrentDiagnosis(false);
  };

  const resetEncounter = () => {
    setEncounter(undefined);
    setPatient(undefined);
    setPatientReference(undefined);
    setEncounterReference(undefined);
    setEncounterDiagnosis(undefined);
  };

  // Return Provider with full API
  const api = {
    isCopiloting,
    currentName,
    currentDiagnosis,
    currentPatient,
    currentEncounter,
    currentItem,
    encounterReference,
    patientReference,
    refreshHistory,
    refreshProcedure,
    refetchCurrentDiagnosis,
    setCurrentItem,
    setCurrentPatient,
    setCurrentEncounter,
    resetEncounter,
    setCurrentEncounterRefrence,
    setCurrentPatientReference,
    setEncounterDiagnosis,
    setCurrentName,
    refreshPatientHistory,
    refreshProceduresList,
    refreshDiagnosisList,
    setIsCopiloting,
    copilot,
    setCopilot,
  };

  return (
    <MedicalEncounterContext.Provider value={api}>
      {children}
    </MedicalEncounterContext.Provider>
  );
}
