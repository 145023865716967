import { useState } from "react";
import WeightInput from "../../../../pages/health/encounters/cards/vitals/components/WeightInput";
import RespiratoryRateInput from "../../../../pages/health/encounters/cards/vitals/components/RespiratoryRateInput";
import HeartRateInput from "../../../../pages/health/encounters/cards/vitals/components/HeartRateInput";
import TemperatureInput from "../../../../pages/health/encounters/cards/vitals/components/TemperatureInput";
import HeightInput from "../../../../pages/health/encounters/cards/vitals/components/HeightInput";
import OxygenSaturationInput from "../../../../pages/health/encounters/cards/vitals/components/OxygenSaturationInput";
import BloodPressureInput from "../../../../pages/health/encounters/cards/vitals/components/BloodPressureInput";
import BmiInput from "../../../../pages/health/encounters/cards/vitals/components/BmiInput";
import BloodGlucoseInput from "../../../../pages/health/encounters/cards/vitals/components/BloodGlucoseInput";
import { Grid, Typography } from "@mui/material";
import HeadCircumferenceInput from "../../../../pages/health/encounters/cards/vitals/components/HeadCircumferenceInput";
import PainInput from "../../../../pages/health/encounters/cards/vitals/components/PainInput";

type VitalsInputFormProps = {
  value: Record<string, any>;
  patient: any;
  onChange: (value: any, modifier: boolean) => void;
  disabled: boolean;
  size?: "small" | "medium";
  isModal?: boolean;
};

const VitalsInputForm = ({
  value,
  patient,
  onChange,
  disabled,
  size,
  isModal,
}: VitalsInputFormProps) => {
  const [painPresent, setPainPresent] = useState(false);

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Vitals</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container columns={isModal ? 8 : 10} spacing={2}>
            <Grid item xs={2}>
              <HeightInput
                disabled={disabled}
                value={value?.height ?? ""}
                patient={patient}
                onChange={(newHeight) => {
                  onChange({ ...value, height: newHeight }, true);
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <WeightInput
                disabled={disabled}
                patient={patient}
                value={value?.weight ?? ""}
                onChange={(newWeight) => {
                  onChange({ ...value, weight: newWeight }, true);
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <BmiInput
                disabled={disabled}
                height={value?.height}
                weight={value?.weight}
                value={value?.bmi ?? ""}
                onChange={(newBmi) => {
                  onChange({ ...value, bmi: newBmi }, false);
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <BloodPressureInput
                disabled={disabled}
                value={value?.bloodPressure ?? ""}
                onChange={(newBloodPressure) => {
                  onChange({ ...value, bloodPressure: newBloodPressure }, true);
                }}
                size={size ?? "small"}
                type="modal"
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container>
                <OxygenSaturationInput
                  disabled={disabled}
                  value={value?.oxygenSaturation ?? ""}
                  onChange={(newOxygenSat) => {
                    onChange({ ...value, oxygenSat: newOxygenSat }, true);
                  }}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <TemperatureInput
                disabled={disabled}
                value={value?.temperature ?? ""}
                onChange={(newTemperature) => {
                  onChange({ ...value, temperature: newTemperature }, true);
                }}
                size="small"
              />
            </Grid>

            <Grid item xs={4}>
              <BloodGlucoseInput
                disabled={disabled}
                value={value?.bloodGlucose ?? ""}
                onChange={(bgl) => {
                  onChange({ ...value, bloodGlucose: bgl }, true);
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <HeadCircumferenceInput
                disabled={disabled}
                value={value?.headCircumference ?? ""}
                size="small"
                onChange={(hc) =>
                  onChange({ ...value, headCircumference: hc }, true)
                }
              />
            </Grid>

            <Grid item xs={4}>
              <HeartRateInput
                disabled={disabled}
                value={value?.heartRate ?? ""}
                onChange={(newHeartRate) => {
                  onChange({ ...value, heartRate: newHeartRate }, true);
                }}
                size="small"
                isModal={isModal}
              />
            </Grid>
            <Grid item xs={4}>
              <RespiratoryRateInput
                disabled={disabled}
                value={value?.respiratoryRate ?? ""}
                onChange={(newRespiratoryRate) => {
                  onChange(
                    { ...value, respiratoryRate: newRespiratoryRate },
                    true,
                  );
                }}
                size="small"
                isModal={isModal}
              />
            </Grid>
            {/* <Grid item xs={12} mt={1}>
              <PainInput
                patient={patient}
                disabled={disabled || !painPresent}
                value={value?.pain}
                onChange={(newPain) => {
                  console.log(newPain);
                  onChange({ ...value, pain: newPain }, true);
                }}
                size={size}
                showCheckbox={true}
                capturePain={painPresent}
                onChangeExperiencingPain={setPainPresent}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VitalsInputForm;
