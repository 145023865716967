import { Divider, Grid, Typography } from "@mui/material";
import { FC } from "react";
import {
  enableQuestion,
  QuestionnaireItemProps,
  QuestionnaireItemTypes,
} from ".";
import NotesInput from "../../../../../components/form/NotesInput";

const SubGroupItems: FC<QuestionnaireItemProps> = ({
  item,
  onChange,
  answers,
  enableGroupNote,
  onChangeNote,
  notes,
  disabled,
  groupAction,
}) => {
  return (
    <Grid container xs={12}>
      {item?.item?.map ? (
        <>
          <Grid item xs={12} key={`subgroup-${item.linkId}`}>
            <Typography variant="subtitle1">{item.text}</Typography>
            <Divider />
            <Grid
              container
              spacing={1}
              key={`subgroup-container-${item.linkId}`}
            >
              {item.item.map((item, i) => {
                const QuestionnaireComponent = item.type
                  ? QuestionnaireItemTypes[item.type]
                  : QuestionnaireItemTypes["question"];

                return (
                  <>
                    {enableQuestion(item, answers, onChange) && (
                      <Grid
                        item
                        xs={item.type === "auto-generate" ? 6 : 12}
                        key={`question-${i * 0.25}`}
                      >
                        <QuestionnaireComponent
                          index={i}
                          item={item}
                          disabled={disabled}
                          onChange={onChange}
                          value={answers[item?.linkId]}
                          answers={answers}
                          initialValue={answers[item?.linkId]}
                          answerValueSet={item.answerValueSet}
                          isMultiple={item.type === "multi-choice"}
                          formula={item.formula}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}{" "}
            </Grid>
            {enableGroupNote && (
              <Grid item xs={12}>
                <NotesInput
                  value={notes[item.linkId]}
                  onChange={(value) => {
                    onChangeNote(value, item);
                  }}
                  disabled={disabled}
                  label="Physician Note"
                  placeholderText="Enter Physician note"
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography>Unable to render this group of questions</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SubGroupItems;
