import { useState } from "react";

type TUsePhysicalAssessment = {
  answers: Record<string, any>;
  notes: Record<string, any>;
  response: Record<string, any>;
  setAnswers: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setNotes: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setResponse: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

export default function useQuestionnaireResponse(
  data?: Record<string, any>,
): TUsePhysicalAssessment {
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [notes, setNotes] = useState<Record<string, any>>({});
  const [response, setResponse] = useState(structuredClone(data ?? {}));

  return {
    answers,
    notes,
    response,
    setAnswers,
    setNotes,
    setResponse,
  } as const;
}
