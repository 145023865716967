import CodingSystems from "../../../../../../constants/CodingSystems";
import { ReferenceRange, Vital } from "../../../../../../types/Vitals";
import { CodeableConcept } from "../../../../../../types/codeableConcept";
import { Reference } from "../../../../../../types/reference";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";

type CreateDefaultVital = (
  encounterReference: Reference,
  patientReference: Reference,
  codingSystem: string,
  vitalCode: string,
  vitalDisplay: string,
  unit: string,
  value?,
  method?: CodeableConcept,
  referenceRange?: [ReferenceRange],
  activity?: string,
  component?,
) => Vital;

export const createDefaultVital: CreateDefaultVital = (
  encounterReference,
  patientReference,
  codingSystem,
  vitalCode,
  vitalDisplay,
  unit,
  value?,
  method?,
  referenceRange?,
  activity?,
  component?,
): Vital => {
  return {
    status: "preliminary",
    encounter: encounterReference,
    subject: patientReference,
    valueQuantity: { unit, value },
    code: createCodeableConcept(codingSystem, vitalCode, vitalDisplay),
    category: [
      createCodeableConcept(
        CodingSystems.OBSERVATION_CATEGORY,
        "vital-signs",
        "Vital Signs",
      ),
    ],
    method,
    referenceRange,
    activity,
    component,
  };
};
